import { config } from 'config';

export const chartProps = {
  props: {
    title: { text: null },
    credits: { enabled: false },
    legend: { enabled: false }
  },
  marker: {
    enabled: false,
    states: {
      hover: { enabled: false }
    }
  },
  chartStyle: {
    style: {
      color: '#ffffff',
      fontSize: '1.2rem',
      fontFamily: 'Roboto',
      cursor: 'pointer'
    }
  }
};

export const focusAreaWeekDays = [
  { value: 0, label: 'Sun' },
  { value: 1, label: 'Mon' },
  { value: 2, label: 'Tue' },
  { value: 3, label: 'Wed' },
  { value: 4, label: 'Thu' },
  { value: 5, label: 'Fri' },
  { value: 6, label: 'Sat' }
];

export const dashboardReputationPlatforms = [
  { value: 'all', imgStr: 'all', activeImgStr: 'all-active' },
  { value: 'yelp', imgStr: 'yelp', activeImgStr: 'yelp-active' },
  { value: 'facebook', imgStr: 'fb', activeImgStr: 'fb-active' },
  { value: 'google', imgStr: 'gmb', activeImgStr: 'gmb-active' }
];

export const countryCodeList = [
  { code: 'US', name: 'United States of America' },
  { code: 'CA', name: 'Canada' }
];

export const statesListCanada = [
  {
    name: 'Alberta',
    abbreviation: 'AB'
  },
  {
    name: 'British Columbia',
    abbreviation: 'BC'
  },
  {
    name: 'Manitoba',
    abbreviation: 'MB'
  },
  {
    name: 'New Brunswick',
    abbreviation: 'NB'
  },
  {
    name: 'Newfoundland and Labrador',
    abbreviation: 'NL'
  },
  {
    name: 'Northwest Territories',
    abbreviation: 'NT'
  },
  {
    name: 'Nova Scotia',
    abbreviation: 'NS'
  },
  {
    name: 'Nunavut',
    abbreviation: 'NU'
  },
  {
    name: 'Ontario',
    abbreviation: 'ON'
  },
  {
    name: 'Prince Edward Island',
    abbreviation: 'PE'
  },
  {
    name: 'Quebec',
    abbreviation: 'QC'
  },
  {
    name: 'Saskatchewan',
    abbreviation: 'SK'
  },
  {
    name: 'Yukon Territory',
    abbreviation: 'YT'
  }
];

export const statesListAmerica = [
  {
    name: 'Alabama',
    abbreviation: 'AL'
  },
  {
    name: 'Alaska',
    abbreviation: 'AK'
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS'
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ'
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR'
  },
  {
    name: 'California',
    abbreviation: 'CA'
  },
  {
    name: 'Colorado',
    abbreviation: 'CO'
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT'
  },
  {
    name: 'Delaware',
    abbreviation: 'DE'
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC'
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM'
  },
  {
    name: 'Florida',
    abbreviation: 'FL'
  },
  {
    name: 'Georgia',
    abbreviation: 'GA'
  },
  {
    name: 'Guam',
    abbreviation: 'GU'
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI'
  },
  {
    name: 'Idaho',
    abbreviation: 'ID'
  },
  {
    name: 'Illinois',
    abbreviation: 'IL'
  },
  {
    name: 'Indiana',
    abbreviation: 'IN'
  },
  {
    name: 'Iowa',
    abbreviation: 'IA'
  },
  {
    name: 'Kansas',
    abbreviation: 'KS'
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY'
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA'
  },
  {
    name: 'Maine',
    abbreviation: 'ME'
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH'
  },
  {
    name: 'Maryland',
    abbreviation: 'MD'
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA'
  },
  {
    name: 'Michigan',
    abbreviation: 'MI'
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN'
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS'
  },
  {
    name: 'Missouri',
    abbreviation: 'MO'
  },
  {
    name: 'Montana',
    abbreviation: 'MT'
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE'
  },
  {
    name: 'Nevada',
    abbreviation: 'NV'
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH'
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ'
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM'
  },
  {
    name: 'New York',
    abbreviation: 'NY'
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC'
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND'
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP'
  },
  {
    name: 'Ohio',
    abbreviation: 'OH'
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK'
  },
  {
    name: 'Oregon',
    abbreviation: 'OR'
  },
  {
    name: 'Palau',
    abbreviation: 'PW'
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA'
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR'
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI'
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC'
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD'
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN'
  },
  {
    name: 'Texas',
    abbreviation: 'TX'
  },
  {
    name: 'Utah',
    abbreviation: 'UT'
  },
  {
    name: 'Vermont',
    abbreviation: 'VT'
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI'
  },
  {
    name: 'Virginia',
    abbreviation: 'VA'
  },
  {
    name: 'Washington',
    abbreviation: 'WA'
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV'
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI'
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY'
  }
];

export const reviewSiteOptions = [
  {
    label: 'Keep Client Settings',
    value: ''
  },
  {
    label: 'Facebook',
    value: 'facebook_page_url'
  },
  {
    label: 'Yelp',
    value: 'yelp_page_url'
  },
  {
    label: 'Google',
    value: 'google_page_url'
  },
  {
    label: 'Other',
    value: 'custom_social_media_page_url'
  }
];

export const surveyTable = [
  { label: 'Questions', value: 'question', identifier: 1 },
  { label: 'My Score', value: 'scorePercentage', identifier: 2 },
  { label: 'Vs. Last Period', value: 'lastPeriodCmp', identifier: 3, comparison: 'lastPeriod', dependency: 'scorePercentage' },
  { label: 'Questions', value: 'scorePercentage', identifier: 4, isHubDropDown: true, isHubType: true },
  { label: 'My Score vs. Company', value: 'lastPeriodCmp', identifier: 5, comparison: 'lastPeriod', dependency: 'scorePercentage', isHubType: true }
];

export const teamList = [
  { label: 'Name', value: 'name' },
  { label: 'Customers', value: 'customerCount' },
  { label: 'Role', value: 'role' },
  { label: 'Average Survey Rating', value: 'averageRating' }
];

export const locationList = [
  { label: 'Location', value: 'name' },
  { label: 'Surveys Sent', value: 'surveySentCount' },
  { label: 'Surveys Completed', value: 'surveyCompletedCount' },
  { label: 'Average Rating', value: 'averageRating' },
  { label: 'Customers', value: 'customerCount' }
];

// types Revv Surveys Rattingtypes
export const REVV_SURVEYS_RATING_FILTER = [
  { value: 'all', label: 'All', title: 'All' },
  { value: '5', label: '5 Stars', variant: 'five-star' },
  { value: '4', label: '4 Stars', variant: 'four-star' },
  { value: '3', label: '3 Stars', variant: 'three-star' },
  { value: '2', label: '2 Stars', variant: 'two-star' },
  { value: '1', label: '1 Star', variant: 'one-star' }
];

// types RevvSurveytypes
export const REVV_SURVEYS_OPTION_FILTER = [
  { value: 'all', label: 'Any status', title: 'Ratings' },
  { value: 'surveys_sent', label: 'Surveys Sent' },
  { value: 'surveys_not_sent', label: 'Surveys Not Sent' },
  { value: 'completed', label: 'Surveys Completed' },
  { value: 'agreed_to_review', label: 'Agreed to Review' },
  { value: 'left_feedback', label: 'Left a Comment' },
  { value: 'contact_requested', label: 'Contact Requested' }
];

export const REVV_LEADERBOARD_PAGE_OPTIONS = [
  { value: '', label: 'Social', title: 'Social' },
  { value: 'operations', label: 'Operations', title: 'Operations' }
];

export const medals = [
  `${config.cdnRevvImgUrl}/leaderboard/rank1.svg`,
  `${config.cdnRevvImgUrl}/leaderboard/rank2.svg`,
  `${config.cdnRevvImgUrl}/leaderboard/rank3.svg`,
  `${config.cdnRevvImgUrl}/leaderboard/no-award.svg`
];

export const nonPspLeaderboardTableValues = [
  { label: 'Ranking', value: 'rank', teamsTotalDataKey: 'totalUser', locationTotalDataKey: 'totalLocation', tableDataKey: 'name' },
  { label: 'Customers', value: 'customerCount', isSortable: true, sortStr: 'customers_count', teamsTotalDataKey: 'customerCount', tableDataKey: 'customerCount' },
  {
    label: 'Surveys Sent',
    value: 'surveySentCount',
    isSortable: true,
    sortStr: 'surveys_sent_count',
    teamsTotalDataKey: 'surveySent',
    locationTotalDataKey: 'surveySentCount',
    tableDataKey: 'surveySentCount'
  },
  {
    label: 'Surveys Cancelled',
    value: 'surveyCancelledCount',
    isSortable: true,
    sortStr: 'surveys_cancelled_count',
    teamsTotalDataKey: 'surveyCancelled',
    locationTotalDataKey: 'surveyCancelledCount',
    tableDataKey: 'surveyCancelledCount'
  },
  {
    label: 'Surveys Completed',
    value: 'surveyCompletedCount',
    isSortable: true,
    sortStr: 'surveys_completed_count',
    teamsTotalDataKey: 'surveyCompleted',
    locationTotalDataKey: 'surveyCompletedCount',
    tableDataKey: 'surveyCompletedCount'
  },
  {
    label: 'Agreed to Review',
    value: 'surveyAgreedToReviewCount',
    isSortable: true,
    sortStr: 'surveys_agreed_to_review_count',
    teamsTotalDataKey: 'surveyAgreedToReview',
    locationTotalDataKey: 'surveyAgreedCount',
    tableDataKey: 'surveyAgreedToReviewCount'
  },
  {
    label: 'Average Rating',
    value: 'averageRating',
    isSortable: true,
    sortStr: 'average_rating',
    teamsTotalDataKey: 'surveyAvgRating',
    locationTotalDataKey: 'surveyAverageRatingCreated',
    tableDataKey: 'averageRating'
  },
  { label: 'Scores', value: 'score', isSortable: true, sortStr: 'score' }
];

export const customerList = [
  { label: 'Customers', value: 'firstName', classStr: 'customer-name' },
  { label: 'Total Visits', value: 'surveysCreatedCount', classStr: 'total-visits', sortLabel: 'local_surveys_created_count' },
  { label: 'Survey Sent', value: 'surveysSentCount', classStr: 'survey-sent', sortLabel: 'local_surveys_completed_count' },
  { label: 'Survey Completed', value: 'surveysCompletedCount', classStr: 'survey-completed', sortLabel: 'local_surveys_sent_count' },
  { label: 'Average Rating', value: 'averageRating', classStr: 'lbl action-header', sortLabel: 'local_average_rating' },
  { label: '', value: 'update', classStr: '' }
];

export const userRole = [
  { label: 'super_admin', name: 'Super Admin' },
  { label: 'partner', name: 'Partner' },
  { label: 'admin', name: 'Admin' },
  { label: 'employee', name: 'Employee' }
];

export const surveyStatsCard = [
  { value: 'totalCount', label: 'TOTAL VISITS', classStr: 'total-visit cur-pointer', pathStr: 'all' },
  { value: 'sentCount', label: 'Surveys Sent', classStr: 'survey-sent survey cur-pointer', pathStr: 'surveys_sent', isImgBox: true },
  { value: 'completedCount', label: 'Surveys Completed', classStr: 'survey-completed cur-pointer', pathStr: 'completed', isImgBox: true },
  { value: 'averageCount', label: 'Average Rating', classStr: 'avg-rating', isImgBox: true },
  { value: 'leftCount', label: 'Left a Comment', classStr: 'left-comment cur-pointer', pathStr: 'left_feedback', isImgBox: true },
  { value: 'agreedCount', label: 'Agreed to Review', classStr: 'agreed-to-review cur-pointer', pathStr: 'agreed_to_review', isImgBox: true }
];

export const globalPriorityPlatformList = [
  { label: 'Facebook', value: 'facebook_page_url' },
  { label: 'Yelp', value: 'yelp_page_url' },
  { label: 'Google', value: 'google_page_url' },
  { label: 'Other', value: 'custom_social_media_page_url' }
];

export const priorityList = [
  { label: 'First Priority', value: 'fp', key: 'all_first_priority_platform' },
  { label: 'Second Priority', value: 'sp', key: 'all_second_priority_platform' },
  { label: 'Third Priority', value: 'tp', key: 'all_third_priority_platform' }
];

export const settingsCheckOptions = {
  surveyType: [
    { label: 'Emoji Survey (5 emojis)', value: 'standard' },
    { label: 'Numerical Survey (1-5)', value: 'numeric' },
    { label: 'Numerical Survey (1-10)', value: 'numeric_one_to_ten' },
    { label: 'NPS Survey (0-10)', value: 'nps' }
  ],
  reviewSites: [
    { label: 'Disable review sites', value: 'disabled' },
    { label: 'Show all review sites', value: 'all' },
    { label: 'Select a review site automatically', value: 'automatically' }
  ],
  leaveReviews: [
    { label: 'Everyone', value: 'all' },
    { label: 'Only those with a positive survey response', value: 'positive' }
  ],
  surveyDelivery: [
    { label: 'Text Only', value: 'sms' },
    { label: 'Text Preferred', value: 'sms_preferred' },
    { label: 'Email Only', value: 'email' },
    { label: 'Email Preferred', value: 'email_preferred' },
    { label: 'Text and Email', value: 'sms_and_email' }
  ],
  onOff: [
    { label: 'Yes', value: true },
    { label: 'No', value: false }
  ],
  editSurvey: [
    { label: 'Business Name', value: 'business_name' },
    { label: ' Location Name', value: 'location_name' },
    { label: 'Employee Name', value: 'employee_name' },
    { label: 'Employee First Name', value: 'employee_firstname' }
  ]
};

export const tagNameList = [
  { tag: 'businessname', typo: ' will insert your business name into the text (required).' },
  { tag: 'locationname', typo: ' will insert your location name into the text.' },
  { tag: 'employeefirstname', typo: " will insert the employee's first name into the text." },
  { tag: 'employeelastname', typo: " will insert the employee's last name into the text." },
  { tag: 'customerfirstname', typo: " will insert the customer's first name into the text." },
  { tag: 'customerlastname', typo: " will insert the customer's last name into the text." }
];

export const couponServiceList = [
  { label: 'Disable Coupons', value: false },
  { label: 'Enable Coupons', value: true }
];

export const surveySmileyData = [
  { text: 'Excellent', emojiSrc: 'smiley-hearteyes' },
  { text: 'Good', emojiSrc: 'smiley-bigeyes' },
  { text: 'OK', emojiSrc: 'smiley-normal' },
  { text: 'Disappointing', emojiSrc: 'smiley-disappointed' },
  { text: 'Terrible', emojiSrc: 'smiley-angry' }
];

export const socialIcons = [
  { text: 'fbImage', imgSrc: 'facebook' },
  { text: 'goImage', imgSrc: 'google-plus' },
  { text: 'yelpImage', imgSrc: 'yelp' }
];

export const nonPspSurveyStatusLegend = [
  {
    label: 'Sent',
    value: 'sent',
    graphColor: '#013B71',
    colorClass: 'bar-blue',
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#013B711A'],
        [1, '#013B7100']
      ]
    }
  },
  {
    label: 'Completed',
    value: 'completed',
    graphColor: '#2DC8A7',
    colorClass: 'bar-green',
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#2DC8A71A'],
        [1, '#2DC8A700']
      ]
    }
  },
  {
    label: 'Review',
    value: 'agreed',
    graphColor: '#FFC259',
    colorClass: 'bar-yellow',
    fillColor: {
      linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
      stops: [
        [0, '#FFC2591A'],
        [1, '#FFC25900']
      ]
    }
  }
];

export const polarityLegend = [
  { label: 'negative', colorClass: 'bar-red', graphColor: '#eb2630', nonPspLabel: 'Negative', nonPspGraphColor: '#FF5C5C' },
  { label: 'neutral', colorClass: 'bar-yellow', graphColor: '#fbbf39', nonPspLabel: 'Neutral', nonPspGraphColor: '#FFC259' },
  { label: 'positive', colorClass: 'bar-green', graphColor: '#37bf8e', nonPspLabel: 'Positive', nonPspGraphColor: '#2DC8A7' }
];

export const conditionBasedPages = ['dashboard', 'surveys', 'analytics_revv', 'revv_settings'];

export const surveyResultDailySummary = [
  {
    label: 'Yes',
    value: 'surveyResultDailySummaryEnabled'
  },
  {
    label: 'No',
    value: 'surveyResultDailySummarDisabled'
  }
];

export const surveyResultEmailOptions = [
  {
    label: 'All Survey Results',
    value: 'allResults'
  },
  {
    label: 'Positive Survey Results Only',
    value: 'completedPositiveSurveys'
  },
  {
    label: 'Negative Survey Results Only',
    value: 'completedNegativeSurveys'
  },
  {
    label: 'Left a Comment',
    value: 'completedSurveysWithFeedback'
  },
  {
    label: 'Wants to be contacted',
    value: 'contactedRequestOnly'
  },
  {
    label: 'Do Not Send Survey Result Notifications',
    value: 'doNotDisturb'
  }
];

export const surveyTableHeader = [
  { identifier: 'name', label: 'Customer' },
  { identifier: 'status', label: 'Status' },
  { identifier: 'surveySentDate', label: 'Sent Date' },
  { identifier: 'dateCompleted', label: 'Date Completed' },
  { identifier: 'reviewStatus', label: 'Review Status' },
  { identifier: 'rating', label: 'Rating' }
];
