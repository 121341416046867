import parse from 'html-react-parser';

import { Paragraph } from 'widgets/Text';
import { apFranContainerTypes } from 'types';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, COMMON_SOCIAL_PROVIDER_TYPE, GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';
import { convertURLAsLink } from 'utils/helpers';
import { SocialMediaLinkPreview } from './SocialMediaLinkPreview';

export const GooglePostPreview = ({
  imagesLimitBasedOnPlatform,
  caption,
  userName,
  profilePic,
  linkData,
  callToActionType,
  callToActionUrl,
  isThumbnailShow
}: apFranContainerTypes.ISocialMediaGooglePostPreview) => {
  return (
    <>
      <div className="p-header">
        {/* <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation imgUrl={profilePic} defaultImg={'user-avatar-common.png'} isNotSvgFormat customName={'Franchisor Profile Item'} />
          </div>
          <div className="p-title">
            <h5>{userName}</h5>
            <Paragraph customText={'Just now'} />
          </div>
        </div>
        <div className="p-header-menu">
          <div className="insta-dots-rotate">
            <ImageValidation isImgValid defaultImg="share-dots" customName="shareDots" />
            <ImageValidation isImgValid defaultImg="more-dots-v" customName="moreDots" />
          </div>
        </div> */}
      </div>
      <div className="p-body previewtLinks p-0">
        <div className="am-media-preview pmg">
          <div>
            <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0] || linkData?.link_preview_image_url} customName={'Image asset'} />
          </div>
        </div>
        <div className="msg-wrapper">
          <Paragraph customClassname="gm-text" customText={caption} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
        </div>
      </div>
      {linkData?.id ? (
        <SocialMediaLinkPreview
          imageUrl={linkData?.link_preview_image_url || null}
          linkPreviewTitle={linkData?.link_preview_title || ''}
          linkUrl={linkData?.url || ''}
          isShowThumbnail={isThumbnailShow || false}
          linkPreviewDescription={linkData?.link_preview_description || ''}
          socialMedia={COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE}
          isLinkCustomized={linkData?.link_preview_customized || false}
          actionType={callToActionType}
        />
      ) : null}
      {callToActionType && callToActionUrl ? (
        <div className="linkWrap">
          <span>{parse(convertURLAsLink(callToActionUrl, GOOGLE_CALL_TO_ACTION_SELECT_OPTIONS.find((option) => option.value === callToActionType)?.label || '', 'add'))}</span>
        </div>
      ) : null}
    </>
  );
};
