import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ImageGrid } from 'react-fb-image-video-grid';

import { Image } from 'widgets/Media';
import { apAccountContainerTypes, IStore } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, MG_MEDIA_TYPE, SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO } from 'utils/constants';
import {
  FacebookPostPreview,
  FacebookReelPreview,
  FacebookStoryPreview,
  GooglePostPreview,
  InstagramPostPreview,
  InstagramStoryPreview,
  TiktokPreview,
  TwitterPostPreview,
  LinkedinPostPreview
} from 'widgets/Preview';
import { getPermittedImgsBasedOnPlatform } from 'utils/helpers';
import { PLATFORM_FILTER } from 'analytics/utils';

export const RightCreatorPreviewSection = (props: apAccountContainerTypes.IAddPostSocialPlatformProps) => {
  const { accessibleSocialMedia } = props;
  // const { id, userOwnership } = useAccountSwitcherData();

  const instagramPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.photoUrl);
  const facebookPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.fbPhotoPreviewObj.photoUrl);
  const brandImageUrl = useSelector((state: IStore) => state.accountSwitcher.brandImageUrl ?? '');
  // const menuAccess = useSelector((state: IStore) => state.accountSwitcher.menu_access);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const { customText, personalizedText, postStatus, isThumbnailShow } = contentObj;

  const [playVideo, setPlayVideo] = useState<string[]>([]);

  useEffect(() => {
    if (!accessibleSocialMedia.length) setPlayVideo([]);
  }, [accessibleSocialMedia.length]); // eslint-disable-line

  const ImageGridCol = useCallback(
    ({ imageUrls, socialMedia }: { imageUrls: string[]; socialMedia: string }) => {
      return (
        <ImageGrid>
          {imageUrls.map((image, index) => (
            <img alt="ig" src={image} key={`selected-${socialMedia}-image-${index}`} />
          ))}
        </ImageGrid>
      );
    },
    [selectedMediaList] // eslint-disable-line
  );

  return (
    <div className="addpost-preview-section-wrp fbInMain asmp-brand all-smp">
      <div className="post-preview">
        {!accessibleSocialMedia.length ? (
          <div className="p-loader">
            <div className="p-mock-header heading">
              <div>
                <div className="p-mock-avatar" />
              </div>
              <div className="p-mock-title">
                <div className="p-mock-line" />
              </div>
            </div>
            <div className="card-bg">
              <div className="p-mock-header">
                <div>
                  <div className="p-mock-avatar" />
                </div>
                <div className="p-mock-title">
                  <div className="p-mock-line" />
                  <div className="p-mock-line" />
                </div>
              </div>

              <div className="p-mock-content">
                <div className="p-mock-line" />
              </div>
            </div>
          </div>
        ) : (
          accessibleSocialMedia
            .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b))
            .map((socialMediaName, index) => {
              const imageUrls =
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? contentObj?.facebook_story
                    ? facebookPhotoPreviewUrl.storyUrls || []
                    : selectedMediaList.map((datum) => datum.medium_size_url || '')
                  : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? contentObj?.instagram_story
                    ? instagramPhotoPreviewUrl.storyUrls || []
                    : instagramPhotoPreviewUrl.previewUrls || []
                  : selectedMediaList.map((datum) => datum.medium_size_url || '');
              const imagesLimitBasedOnPlatform = getPermittedImgsBasedOnPlatform(socialMediaName, imageUrls);
              const facebookTags = postStatus.facebook ? selectedFBTagList : [];
              return (customText && customText[socialMediaName]) || selectedMediaList.length || linkData?.id ? (
                <div className={`p-content ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? 'smType-google' : ''}`} key={`brand-creator-preview-${index}`}>
                  <div className="p-content-header">
                    <div className={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'p-avatar-box' : 'p-avatar-box-twitter'}>
                      {/* <div> */}
                      <Image isImageExists={false} customImgName={SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.logo || ''} customName={'More'} />
                      {/* </div> */}
                    </div>
                    <h4>{SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.label || ''}</h4>{' '}
                  </div>
                  <div
                    className={`card-bg ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? 'ig-assets insta-pn-main' : ''}
                    ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? 'in-pn-main' : ''}${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? 'twitx-assets' : ''}${
                      selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? 'cb-tiktok' : ''
                    } 
                  ${
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && contentObj?.facebook_story) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && contentObj?.facebook_reel) ||
                    (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && contentObj?.instagram_story)
                      ? 'fb-in-active'
                      : ''
                  } `}
                  >
                    {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK && (
                      <TiktokPreview
                        userName={topLevelFranchisor}
                        videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                        thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                        description={personalizedText?.tiktok || customText.tiktok || ''}
                      />
                    )}
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                      contentObj?.facebook_story ? (
                        <FacebookStoryPreview userName={topLevelFranchisor} media={selectedMediaList?.[0]} imgStoryUrl={imageUrls[0]} profilePic={brandImageUrl} />
                      ) : contentObj?.facebook_reel ? (
                        <FacebookReelPreview
                          userName={topLevelFranchisor}
                          description={personalizedText.facebook || customText.facebook || ''}
                          videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                          thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                          profilePic={brandImageUrl}
                        />
                      ) : (
                        <FacebookPostPreview
                          socialMedia={socialMediaName}
                          playVideo={playVideo}
                          setPlayVideo={setPlayVideo}
                          imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                          isSocialMediaPostEnabled={contentObj.postStatus[`${socialMediaName}`]}
                          selectedMediaList={selectedMediaList}
                          caption={personalizedText[socialMediaName] || customText[socialMediaName] || ''}
                          userName={topLevelFranchisor}
                          profilePic={brandImageUrl}
                          facebookTags={facebookTags}
                          selectedFBTagList={selectedFBTagList}
                          ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                          linkData={linkData}
                          callToActionType={contentObj.facebook_call_to_action_type}
                          isThumbnailShow={isThumbnailShow}
                        />
                      )
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && selectedMediaList.length ? (
                      contentObj?.instagram_story ? (
                        <InstagramStoryPreview userName={topLevelFranchisor} media={selectedMediaList?.[0]} imgStoryUrl={imageUrls[0]} profilePic={brandImageUrl} />
                      ) : (
                        <InstagramPostPreview
                          socialMedia={socialMediaName}
                          playVideo={playVideo}
                          setPlayVideo={setPlayVideo}
                          imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                          isSocialMediaPostEnabled={contentObj.postStatus[`${socialMediaName}`]}
                          selectedMediaList={selectedMediaList}
                          caption={personalizedText[socialMediaName] || customText[socialMediaName] || ''}
                          userName={topLevelFranchisor}
                          profilePic={brandImageUrl}
                        />
                      )
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? (
                      <TwitterPostPreview
                        socialMedia={socialMediaName}
                        playVideo={playVideo}
                        setPlayVideo={setPlayVideo}
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        isSocialMediaPostEnabled={contentObj.postStatus[`${socialMediaName}`]}
                        selectedMediaList={selectedMediaList}
                        caption={personalizedText[socialMediaName] || customText[socialMediaName] || ''}
                        userName={topLevelFranchisor}
                        profilePic={brandImageUrl}
                        ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                        isThumbnailShow={isThumbnailShow}
                        linkData={linkData}
                      />
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? (
                      <LinkedinPostPreview
                        socialMedia={socialMediaName}
                        playVideo={playVideo}
                        setPlayVideo={setPlayVideo}
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        isSocialMediaPostEnabled={contentObj.postStatus[`${socialMediaName}`]}
                        selectedMediaList={selectedMediaList}
                        caption={personalizedText[socialMediaName] || customText[socialMediaName] || ''}
                        userName={topLevelFranchisor}
                        profilePic={brandImageUrl}
                        ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                        isThumbnailShow={isThumbnailShow}
                        linkData={linkData}
                      />
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                      <GooglePostPreview
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        caption={personalizedText[socialMediaName] || customText[socialMediaName] || ''}
                        userName={topLevelFranchisor}
                        profilePic={brandImageUrl}
                        linkData={linkData}
                        callToActionType={contentObj.google_call_to_action_type}
                        callToActionUrl={contentObj.google_call_to_action_url}
                        isThumbnailShow={isThumbnailShow}
                      />
                    ) : null}
                  </div>
                </div>
              ) : null;
            })
        )}
      </div>
    </div>
  );
};
