import { AP_FRAN_SHOW_LINE_BREAK_TYPES } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';

export const TiktokPreview = ({ userName, videoUrl, description, thumbnailUrl }: { userName: string; videoUrl: string; description: string; thumbnailUrl: string }) => {
  return (
    <div className="tt-wraps tiktok-pn-preview">
      <div className="tt-asset-wrap">
        <ReactVideoPlayer
          videoUrl={videoUrl || ''}
          customClassname={'video full-width'}
          isPlayControl={false} // playVideo.includes(socialMediaName)
          light={thumbnailUrl}
        />
      </div>
      <div className="tiktok-Overlay">
        <div className="play-btn">
          <ImageValidation isImgValid defaultImg={'play-tiktok'} customName={'tiktokPlay'} />
        </div>
        <div className="tt-topContent d-none">
          <h3>
            <span className="ttcLabel">Following | </span>
            <span className="ttcValue">For you</span>
          </h3>
        </div>

        <div className="tt-BaseContent">
          <div className="ttb-top">
            <div className="ttb-cnt-left">
              <h3>
                <span className="ttcValue">@{userName}</span>
                <div className="ttcTime">
                  <span className="rDot"> · </span>
                  <Paragraph customText={'Just now'} />
                </div>
              </h3>
              <Paragraph customClassname="gm-text" customText={description || ''} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />

              <div className="tt-translation d-none">
                <h3>See translation</h3>
                <div className="tt-st-wrap">
                  <div className="tt-st-item">
                    <ImageValidation isImgValid defaultImg={'music-tiktok'} customName={'Original Sound'} />
                    <span className="tt-st-head">Original Sound</span>
                  </div>
                  <div className="tt-st-item">
                    <ImageValidation isImgValid defaultImg={'mute-tiktok'} customName={'Mute'} />
                    <span className="tt-st-head">Mute</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ttb-icons-right">
              <ul>
                <li className="ttn-icons tt-n-profile">
                  <div className="ttn-i-wrap">
                    <ImageValidation customClassname="r-profile" defaultImg={'rIcon'} customName={'Profile Image'} />
                    <ImageValidation isImgValid customClassname="r-red-icon" defaultImg={'add-red'} customName={'Follow'} />
                  </div>
                </li>
                <li className="ttn-icons tt-n-slide d-none">
                  <div className="ttn-i-wrap">
                    <ImageValidation isImgValid defaultImg={'tiktok-n-slide'} customName={'Posts'} />
                  </div>
                </li>
                <li className="ttn-icons tt-n-like">
                  <div className="ttn-i-wrap">
                    <ImageValidation isImgValid defaultImg={'tiktok-n-like'} customName={'Like'} />
                  </div>
                  <span className="tt-nav-txt">Like</span>
                </li>
                <li className="ttn-icons tt-n-comment">
                  <div className="ttn-i-wrap">
                    <ImageValidation isImgValid defaultImg={'tiktok-n-comment'} customName={'Comment'} />
                  </div>
                  <span className="tt-nav-txt">Comments</span>
                </li>
                <li className="ttn-icons tt-n-collection">
                  <div className="ttn-i-wrap">
                    <ImageValidation isImgValid defaultImg={'tiktok-n-collection'} customName={'Create new collection'} />
                  </div>
                  <span className="tt-nav-txt">Collections</span>
                </li>
                <li className="ttn-icons tt-n-share">
                  <div className="ttn-i-wrap">
                    <ImageValidation isImgValid defaultImg={'tiktok-n-share'} customName={'Share'} />
                  </div>
                  <span className="tt-nav-txt">Share</span>
                </li>
                {/* <li>
                  <ImageValidation isImgValid defaultImg={'music-r-tiktok'} customName={'Music'} />
                </li> */}
              </ul>
            </div>
          </div>
          <div className="ttb-base">
            <ul>
              <li>
                <ImageValidation isImgValid defaultImg={'home-tiktok'} customName={'Home'} />
                <span className="tt-nav-txt">home</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'search-tiktok'} customName={'Search'} />
                <span className="tt-nav-txt">search</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'add-tiktok'} customName={'Add'} />
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'inbox-tiktok'} customName={'Inbox'} />
                <span className="tt-nav-txt">inbox</span>
              </li>
              <li>
                <ImageValidation isImgValid defaultImg={'profile-tiktok'} customName={'Profile'} />
                <span className="tt-nav-txt">profile</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
