import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ImageGrid } from 'react-fb-image-video-grid';

import { Image } from 'widgets/Media';
import { apAccountContainerTypes, IStore } from 'types';
import { COMMON_SOCIAL_PROVIDER_TYPE, MG_MEDIA_TYPE, APP_TITLE, SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO, SettingsHashtagsActionsVal } from 'utils/constants';
import { getValidInstaProviderName, getPermittedImgsBasedOnPlatform } from 'utils/helpers';
import {
  FacebookPostPreview,
  FacebookReelPreview,
  FacebookStoryPreview,
  GooglePostPreview,
  InstagramPostPreview,
  InstagramStoryPreview,
  TiktokPreview,
  TwitterPostPreview,
  LinkedinPostPreview
} from 'widgets/Preview';
import { PLATFORM_FILTER } from 'analytics/utils';
import { SocialMediaPreviewCommentSec } from '../CommonModal';
import { useActivePlatform } from 'utils/hooks';

export const RightCreatorPreviewSection = ({ accessibleSocialMedia }: apAccountContainerTypes.IAddPostSocialPlatformProps) => {
  const activePlatforms = useActivePlatform();

  const savedPostDetails = useSelector((state: IStore) => state.addPostAccount.savedPostDetails);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const topLevelFranchisor = useSelector((state: IStore) => state.accountSwitcher.topLevelFranchisor);
  const validInstaName = getValidInstaProviderName(accountDetails?.social_media || []);
  // const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const instagramPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.instaPhotoPreviewObj.photoUrl);
  const facebookPhotoPreviewUrl = useSelector((state: IStore) => state.addPostAccount.fbPhotoPreviewObj.photoUrl);
  const hashtagState = useSelector((state: IStore) => state.settingsHashTags.hashTagsState);

  const [playVideo, setPlayVideo] = useState<string[]>([]);

  useEffect(() => {
    if (!accessibleSocialMedia.length) setPlayVideo([]);
  }, [accessibleSocialMedia.length]); // eslint-disable-line

  const ImageGridCol = useCallback(
    ({ imageUrls, socialMedia }: { imageUrls: string[]; socialMedia: string }) => {
      return (
        <ImageGrid>
          {imageUrls.map((image, index) => (
            <img alt="ig" src={image} key={`selected-${socialMedia}-image-${index}`} />
          ))}
        </ImageGrid>
      );
    },
    [selectedMediaList] // eslint-disable-line
  );

  const SocialMediaCommentsPreview = useCallback(
    ({ socialMediaName, userName }: { socialMediaName: string; userName?: string }) => {
      return hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? (
        <SocialMediaPreviewCommentSec socialMedia={socialMediaName} hashtagState={hashtagState} accSocialMedia={accountDetails?.social_media ?? []} userName={userName} />
      ) : null;
    },
    [accountDetails?.social_media, hashtagState]
  );

  return (
    <div className="addpost-preview-section-wrp fbInMain asmp-account all-smp">
      <div className="post-preview">
        {!accessibleSocialMedia.length ? (
          <div className="p-loader">
            <div className="p-mock-header heading">
              <div>
                <div className="p-mock-avatar" />
              </div>
              <div className="p-mock-title">
                <div className="p-mock-line" />
              </div>
            </div>
            <div className="card-bg">
              <div className="p-mock-header">
                <div>
                  <div className="p-mock-avatar" />
                </div>
                <div className="p-mock-title">
                  <div className="p-mock-line" />
                  <div className="p-mock-line" />
                </div>
              </div>

              <div className="p-mock-content">
                <div className="p-mock-line" />
              </div>
            </div>
          </div>
        ) : (
          accessibleSocialMedia
            .sort((a, b) => PLATFORM_FILTER.findIndex((it) => it.value === a) - PLATFORM_FILTER.findIndex((it) => it.value === b))
            .map((socialMediaName, index) => {
              const imageUrls =
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK
                  ? savedPostDetails?.facebook_story
                    ? facebookPhotoPreviewUrl.storyUrls || []
                    : selectedMediaList.map((datum) => datum.medium_size_url || '')
                  : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? savedPostDetails?.instagram_story
                    ? instagramPhotoPreviewUrl.storyUrls || []
                    : instagramPhotoPreviewUrl.previewUrls || []
                  : selectedMediaList.map((datum) => datum.medium_size_url || '');
              const imagesLimitBasedOnPlatform = getPermittedImgsBasedOnPlatform(socialMediaName, imageUrls);
              const facebookTags = savedPostDetails?.use_facebook ? selectedFBTagList : [];
              const socialProfile = accountDetails?.social_media?.find((socialMedia) =>
                socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM
                  ? socialMedia.provider_name === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE
                  : socialMedia.provider_name === socialMediaName
              );

              return savedPostDetails && activePlatforms.includes(socialMediaName) && (savedPostDetails[`${socialMediaName}_text`] || selectedMediaList.length || linkData?.link_preview_title) ? (
                <div className={`p-content ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? 'smType-google' : ''}`} key={`account-creator-preview-${index}`}>
                  <div className="p-content-header">
                    <div className={socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? 'p-avatar-box' : 'p-avatar-box-twitter'}>
                      {/* <div> */}
                      <Image
                        isImageExists={false}
                        customImgName={SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.logo || ''}
                        customName={'Social media'}
                      />
                      {/* </div> */}
                    </div>
                    <h4>{SOCIAL_MEDIA_PREVIEW_TITTLE_AND_LOGO.find((previewObj) => previewObj.medium === socialMediaName)?.label || ''}</h4>
                  </div>
                  <div
                    className={`card-bg ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM ? 'ig-assets insta-pn-main' : ''}
                    ${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? 'in-pn-main' : ''}${socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? 'twitx-assets' : ''}${
                      selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? 'cb-tiktok' : ''
                    }${
                      (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_story) ||
                      (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK && savedPostDetails?.facebook_reel) ||
                      (socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && savedPostDetails?.instagram_story)
                        ? 'fb-in-active'
                        : ''
                    } `}
                  >
                    {socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
                      savedPostDetails?.facebook_story ? (
                        <FacebookStoryPreview
                          userName={socialProfile?.profile_name || APP_TITLE}
                          media={selectedMediaList?.[0]}
                          imgStoryUrl={imageUrls[0]}
                          profilePic={socialProfile?.profile_picture_url || ''}
                        />
                      ) : savedPostDetails?.facebook_reel ? (
                        <>
                          <FacebookReelPreview
                            userName={socialProfile?.profile_name || APP_TITLE}
                            description={savedPostDetails?.facebook_text || ''}
                            videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                            thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                            profilePic={socialProfile?.profile_picture_url || ''}
                          />
                          <SocialMediaCommentsPreview socialMediaName={socialMediaName} />
                        </>
                      ) : (
                        <FacebookPostPreview
                          socialMedia={socialMediaName}
                          hashtagState={hashtagState}
                          accSocialMedia={accountDetails?.social_media ?? []}
                          playVideo={playVideo}
                          setPlayVideo={setPlayVideo}
                          imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                          isSocialMediaPostEnabled={savedPostDetails[`use_${socialMediaName}`]}
                          selectedMediaList={selectedMediaList}
                          caption={savedPostDetails[`${socialMediaName}_text`]}
                          userName={socialProfile?.profile_name || APP_TITLE}
                          profilePic={socialProfile?.profile_picture_url || ''}
                          ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                          facebookTags={facebookTags}
                          selectedFBTagList={selectedFBTagList}
                          linkData={linkData}
                          callToActionType={savedPostDetails?.facebook_call_to_action_type}
                          isThumbnailShow={savedPostDetails?.is_thumbnail_show}
                        />
                      )
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM && selectedMediaList?.length ? (
                      savedPostDetails?.instagram_story ? (
                        <InstagramStoryPreview
                          userName={socialProfile?.profile_name || APP_TITLE}
                          media={selectedMediaList?.[0]}
                          imgStoryUrl={imageUrls[0]}
                          profilePic={socialProfile?.profile_picture_url || ''}
                        />
                      ) : (
                        <InstagramPostPreview
                          socialMedia={validInstaName.includes(socialMediaName) ? validInstaName : socialMediaName}
                          hashtagState={hashtagState}
                          accSocialMedia={accountDetails?.social_media ?? []}
                          playVideo={playVideo}
                          setPlayVideo={setPlayVideo}
                          imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                          isSocialMediaPostEnabled={savedPostDetails[`use_${socialMediaName}`]}
                          selectedMediaList={selectedMediaList}
                          caption={savedPostDetails[`${socialMediaName}_text`]}
                          userName={socialProfile?.profile_name || APP_TITLE}
                          profilePic={socialProfile?.profile_picture_url || ''}
                        />
                      )
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? (
                      <TwitterPostPreview
                        socialMedia={socialMediaName}
                        hashtagState={hashtagState}
                        accSocialMedia={accountDetails?.social_media ?? []}
                        playVideo={playVideo}
                        setPlayVideo={setPlayVideo}
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        isSocialMediaPostEnabled={savedPostDetails[`use_${socialMediaName}`]}
                        selectedMediaList={selectedMediaList}
                        caption={savedPostDetails[`${socialMediaName}_text`]}
                        userName={socialProfile?.profile_name || APP_TITLE}
                        profilePic={socialProfile?.profile_picture_url || ''}
                        ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                        isThumbnailShow={savedPostDetails?.is_thumbnail_show}
                        linkData={linkData}
                      />
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? (
                      <LinkedinPostPreview
                        socialMedia={socialMediaName}
                        hashtagState={hashtagState}
                        accSocialMedia={accountDetails?.social_media ?? []}
                        playVideo={playVideo}
                        setPlayVideo={setPlayVideo}
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        isSocialMediaPostEnabled={savedPostDetails[`use_${socialMediaName}`]}
                        selectedMediaList={selectedMediaList}
                        caption={savedPostDetails[`${socialMediaName}_text`]}
                        userName={socialProfile?.profile_name || APP_TITLE}
                        profilePic={socialProfile?.profile_picture_url || ''}
                        ImageGridCol={({ imageUrls }) => <ImageGridCol imageUrls={imageUrls} socialMedia={socialMediaName} />}
                        isThumbnailShow={savedPostDetails?.is_thumbnail_show}
                        linkData={linkData}
                      />
                    ) : socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.GOOGLE ? (
                      <GooglePostPreview
                        caption={savedPostDetails[`${socialMediaName}_text`]}
                        imagesLimitBasedOnPlatform={imagesLimitBasedOnPlatform}
                        userName={socialProfile?.profile_name || APP_TITLE}
                        profilePic={socialProfile?.profile_picture_url || ''}
                        linkData={linkData}
                        callToActionType={savedPostDetails?.google_call_to_action_type}
                        callToActionUrl={savedPostDetails?.google_call_to_action_url}
                        isThumbnailShow={savedPostDetails?.is_thumbnail_show}
                      />
                    ) : selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && socialMediaName === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
                      <>
                        <TiktokPreview
                          userName={topLevelFranchisor}
                          videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                          thumbnailUrl={selectedMediaList[0]?.thumbnail_url || ''}
                          description={savedPostDetails?.[`${socialMediaName}_text`] || ''}
                        />
                        {/* <SocialMediaCommentsPreview socialMediaName={socialMediaName} userName={topLevelFranchisor} /> */}
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null;
            })
        )}
      </div>
    </div>
  );
};
