import { Paragraph } from 'widgets/Text';
import { apFranContainerTypes } from 'types';
import { SocialMediaPreviewCommentSec, SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, CREATOR_TWITTER_PREVIEW_FOOTER_ICONS, CREATOR_TWITTER_RIGHT_PREVIEW_FOOTER_ICONS, MG_MEDIA_TYPE, SettingsHashtagsActionsVal } from 'utils/constants';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { SocialMediaLinkPreview } from './SocialMediaLinkPreview';

export const TwitterPostPreview = ({
  socialMedia,
  hashtagState,
  accSocialMedia,
  imagesLimitBasedOnPlatform,
  selectedMediaList,
  playVideo,
  setPlayVideo,
  isSocialMediaPostEnabled,
  caption,
  userName,
  profilePic,
  linkData,
  isThumbnailShow,
  ImageGridCol
}: apFranContainerTypes.ISocialMediaTwitterPostPreview) => {
  return (
    <div className={`cp-sm cps-xt ${hashtagState && hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? 'xt-wc' : 'xt-nc'}`}>
      <div className="cp-group cpg-top">
        <div className="p-header xt-div cp-left">
          <div className="xt-div p-header-title">
            <div className="xt-div p-avatar">
              <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={profilePic} defaultImg="user-avatar-common.png" isNotSvgFormat />
            </div>
            <div className="xt-div xt-comments" />
          </div>
        </div>

        <div className="xt-div cp-right">
          <div className="p-title">
            <div className="xt-div cp-top-cnt vt-top-left">
              <h5>{userName}</h5>
              <p>
                <span className="cp-ht-name">@{userName}</span>
                <div className="cp-sep">
                  <span className="cp-dot">.</span>
                </div>
                <span className="cp-timeStamp">Just now</span>
              </p>
            </div>
            <div className="xt-div cp-top-cnt vt-top-right">
              <div className="cp-more">
                <ImageValidation isImgValid customClassname={'xt-ep'} customName={'Explain this post'} defaultImg="xt-explain" />
                <ImageValidation isImgValid customClassname={'xt-more'} customName={'More'} defaultImg="cp-more" />
              </div>
            </div>
          </div>
          <div className="p-body previewtLinks">
            <div className="msg-wrapper">
              <Paragraph customClassname="gm-text" customText={caption} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
            </div>
            <div className="am-media-preview pmg">
              {selectedMediaList.length ? (
                selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? (
                  imagesLimitBasedOnPlatform?.length > 1 ? (
                    <ImageGridCol imageUrls={imagesLimitBasedOnPlatform} socialMedia={socialMedia} />
                  ) : (
                    <div>
                      <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
                    </div>
                  )
                ) : (
                  <div className="video-outer-element">
                    <ReactVideoPlayer
                      videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                      customClassname={'video'}
                      isPlayControl={playVideo.includes(socialMedia)}
                      onClick={() => {
                        if (isSocialMediaPostEnabled) {
                          setPlayVideo([...playVideo, socialMedia]);
                        }
                      }}
                      light={selectedMediaList[0]?.thumbnail_url}
                    />
                  </div>
                )
              ) : null}
            </div>
          </div>
          {linkData?.id ? (
            <SocialMediaLinkPreview
              imageUrl={linkData?.link_preview_image_url || null}
              linkPreviewTitle={linkData?.link_preview_title || ''}
              linkUrl={linkData?.url || ''}
              isShowThumbnail={isThumbnailShow || false}
              linkPreviewDescription={linkData?.link_preview_description || ''}
              socialMedia={socialMedia}
              isLinkCustomized={linkData?.link_preview_customized || false}
              actionType={null}
            />
          ) : null}

          <div className="gx-double-col-icons">
            <div className="lefticon">
              <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_TWITTER_PREVIEW_FOOTER_ICONS} />
            </div>
            <div className="righticon">
              <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_TWITTER_RIGHT_PREVIEW_FOOTER_ICONS} />
            </div>
          </div>
        </div>
      </div>
      <div className="cp-group cpg-base">
        {hashtagState && hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? (
          <SocialMediaPreviewCommentSec socialMedia={socialMedia} hashtagState={hashtagState} accSocialMedia={accSocialMedia ?? []} userName={userName} />
        ) : null}
      </div>
    </div>
  );
};
