import { apFranContainerTypes } from 'types';
import { SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal';
import { APP_TITLE, COMMON_SOCIAL_PROVIDER_TYPE, CREATOR_TWITTER_PREVIEW_FOOTER_ICONS, CREATOR_TWITTER_RIGHT_PREVIEW_FOOTER_ICONS } from 'utils/constants';
import { ImageValidation } from 'widgets/Media';

export const SocialMediaPreviewCommentSec = ({ hashtagState, accSocialMedia, socialMedia, userName }: apFranContainerTypes.ISocialMediaCommentsPreview) => {
  const selectedSocialMedia = accSocialMedia.find((medium) => medium.provider_name === socialMedia);
  return !selectedSocialMedia ? (
    <></>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.FACEBOOK ? (
    <div className="cmnts-sec vmcWrap vmc-fb">
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer fb-n-cf">
              <span>Just Now</span>
              <span>
                <b>Like</b>
              </span>
              <span>
                <b>Reply</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : [COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_BUSINESS_PAGE, COMMON_SOCIAL_PROVIDER_TYPE.INSTAGRAM_PUBLIC_CONTENT].includes(socialMedia) ? (
    <div className="cmnts-sec vmcWrap vmc-insta">
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>
                <span className="insta-c-un">{selectedSocialMedia?.profile_name || APP_TITLE}</span>
              </h5>

              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer insta-c-hlr">
              <span>Just Now</span>
              <span>Likes</span>
              <span>Reply</span>
            </div>
          </div>
        </div>
        <div className="vmcIcons insta-mc-icons">
          <ImageValidation isImgValid customName={'Insta Like'} defaultImg="insta-n-like" />
        </div>
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TWITTER ? (
    <>
      <div className="p-header xt-div cp-left">
        <div className="xt-div p-header-title">
          <div className="xt-div p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
        </div>
      </div>

      <div className="xt-div cp-right">
        <div className="p-title">
          <div className="xt-div cp-top-cnt vt-top-left">
            <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
            <p>
              <span className="cp-ht-name">@{userName}</span>
              <div className="cp-sep">
                <span className="cp-dot">.</span>
              </div>
              <span className="cp-timeStamp">Just now</span>
            </p>
          </div>
          <div className="xt-div cp-top-cnt vt-top-right">
            <div className="cp-more">
              <ImageValidation isImgValid customClassname={'xt-ep'} customName={'Explain this post'} defaultImg="xt-explain" />
              <ImageValidation isImgValid customClassname={'xt-more'} customName={'More'} defaultImg="cp-more" />
            </div>
          </div>
        </div>

        <div className="blueTags">
          <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
        </div>
        <div className="gx-double-col-icons">
          <div className="lefticon">
            <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_TWITTER_PREVIEW_FOOTER_ICONS} />
          </div>
          <div className="righticon">
            <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_TWITTER_RIGHT_PREVIEW_FOOTER_ICONS} />
          </div>
        </div>
      </div>
    </>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.LINKEDIN ? (
    <div className="cmnts-sec vmcWrap vmc-linkedin">
      {/* <div className="in-mr-wrap">
        <span>Most relevant</span> <ImageValidation isImgValid customClassname={'in-down-arrow'} customName={'Most relevant'} defaultImg="pn-arrw-down" />
      </div> */}
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{selectedSocialMedia?.profile_name || APP_TITLE}</h5>
              {/* <span className="in-light-txt">followers</span> */}

              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>

              <div className="in-c-lr cmnts-footer">
                <span>Like</span>
                <span>Reply</span>
              </div>
            </div>
          </div>
          <div className="in-tsp in-c-tsm">
            <span className="in-light-txt">Just Now</span>
            <ImageValidation isImgValid defaultImg={'in-pn-more'} customClassname="in-p-more" customName={'More'} />
          </div>
        </div>
      </div>
    </div>
  ) : socialMedia === COMMON_SOCIAL_PROVIDER_TYPE.TIKTOK ? (
    <div className="cmnts-sec vmcWrap vmc-tiktok">
      <h5>Comments</h5>
      <div className="p-header">
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation isImgValid customName={'Location Profile Item'} imgUrl={selectedSocialMedia?.profile_picture_url} defaultImg="user-avatar-common.png" isNotSvgFormat />
          </div>
          <div className="p-title">
            <div className="lg-bg">
              <h5>{userName}</h5>
              <div className="blueTags">
                <span className="hngfxw3">{hashtagState.saved_hashtags?.join(' ')}</span>
              </div>
            </div>
            <div className="cmnts-footer ttn-cf">
              <span>Just Now</span>
              <div className="ttn-cf-icon">
                <ImageValidation isImgValid defaultImg={'tiktok-n-like-c-red'} customName={'Liked'} />
              </div>
              <span>
                <b>Reply</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
