import { SocialMediaPreviewCommentSec, SocialMediaPreviewFooterIcons } from 'containers/Content/Creator/CommonModal';
import { apFranContainerTypes } from 'types';
import { AP_FRAN_SHOW_LINE_BREAK_TYPES, CREATOR_FB_PREVIEW_FOOTER_ICONS, MG_MEDIA_TYPE, SettingsHashtagsActionsVal } from 'utils/constants';
import { getValidFBTagPreview } from 'utils/helpers';
import { ImageValidation, ReactVideoPlayer } from 'widgets/Media';
import { Paragraph } from 'widgets/Text';
import { SocialMediaLinkPreview } from './SocialMediaLinkPreview';

export const FacebookPostPreview = ({
  socialMedia,
  hashtagState,
  accSocialMedia,
  imagesLimitBasedOnPlatform,
  selectedMediaList,
  playVideo,
  setPlayVideo,
  isSocialMediaPostEnabled,
  caption,
  userName,
  profilePic,
  facebookTags,
  ImageGridCol,
  selectedFBTagList,
  linkData,
  isThumbnailShow,
  callToActionType
}: apFranContainerTypes.ISocialMediaFacebookPostPreview) => {
  return (
    <div className="fb-pn-preview">
      <div className="p-header fb-pn-wrap" key={`creator-profile`}>
        <div className="p-header-title">
          <div className="p-avatar">
            <ImageValidation imgUrl={profilePic} defaultImg={'user-avatar-common.png'} customName={'Franchisor Profile Item'} isNotSvgFormat />
          </div>
          <div className="p-title">
            <h5>{userName}</h5>
            <div className="tsp">
              <Paragraph customText={'Just now'} />
              <span className="fb-pn-dot"> · </span>
              <div className="publicView">
                <ImageValidation isImgValid defaultImg={'fb-n-public'} customClassname="fbn-public" customName={'Public'} />
              </div>
            </div>
          </div>
        </div>
        <div className="fb-pm">
          <ImageValidation isImgValid defaultImg={'fb-n-more'} customClassname="fbn-more" customName={'More'} />
        </div>
      </div>
      <div className="p-body previewtLinks p-0 fb-pn-body">
        <div className="msg-wrapper">
          <Paragraph facebookTagList={facebookTags} customClassname="gm-text" customText={getValidFBTagPreview(caption, selectedFBTagList)} actionType={AP_FRAN_SHOW_LINE_BREAK_TYPES.PREVIEW} />
        </div>
        <div className="am-media-preview pmg">
          {selectedMediaList.length >= 1 && selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.PHOTO ? (
            imagesLimitBasedOnPlatform.length > 1 ? (
              <ImageGridCol imageUrls={imagesLimitBasedOnPlatform} socialMedia="facebook" />
            ) : (
              <div className="fb-pn-img-element">
                <ImageValidation imgUrl={imagesLimitBasedOnPlatform[0]} customName={'Image asset'} />
              </div>
            )
          ) : null}
          {selectedMediaList[0]?.media_type === MG_MEDIA_TYPE.VIDEO && (
            <div className="video-outer-element">
              <ReactVideoPlayer
                videoUrl={selectedMediaList[0]?.medium_size_url || ''}
                customClassname={'video'}
                isPlayControl={playVideo.includes('facebook')}
                onClick={() => {
                  if (isSocialMediaPostEnabled) {
                    setPlayVideo([...playVideo, 'facebook']);
                  }
                }}
                light={selectedMediaList[0]?.thumbnail_url}
              />
            </div>
          )}
        </div>
      </div>
      {linkData?.id ? (
        <SocialMediaLinkPreview
          imageUrl={linkData?.link_preview_image_url || null}
          linkPreviewTitle={linkData?.link_preview_title || ''}
          linkUrl={linkData?.url || ''}
          isShowThumbnail={isThumbnailShow || false}
          linkPreviewDescription={linkData?.link_preview_description || ''}
          socialMedia={socialMedia}
          isLinkCustomized={linkData?.link_preview_customized || false}
          actionType={callToActionType}
        />
      ) : null}

      <SocialMediaPreviewFooterIcons socialMedia={socialMedia} footerIconsList={CREATOR_FB_PREVIEW_FOOTER_ICONS} />
      {hashtagState && hashtagState.action_status === SettingsHashtagsActionsVal.IN_COMMENT && hashtagState.saved_hashtags?.length ? (
        <SocialMediaPreviewCommentSec socialMedia={socialMedia} hashtagState={hashtagState} accSocialMedia={accSocialMedia ?? []} />
      ) : null}
    </div>
  );
};
