export enum HttpMethods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export const API = {
  /* AUTH */
  login: { apiPath: 'sessions', action: HttpMethods.POST },
  logout: { apiPath: 'sessions/token', action: HttpMethods.DELETE },
  forgotPassword: { apiPath: 'forgot_password', action: HttpMethods.POST },
  resetPassword: { apiPath: 'reset_password', action: HttpMethods.POST },
  userAndRoleListRequest: { apiPath: 'api/user/role/list', action: HttpMethods.GET },
  accountConfirmation: { apiPath: 'users/find_email', action: HttpMethods.GET },
  /* COMMON */
  accountSwitcher: { apiPath: 'account_switcher', action: HttpMethods.GET },
  menuAccessiblityGet: { apiPath: ':userOwnerShip/:id', action: HttpMethods.GET },
  menuAccessiblityUpdate: { apiPath: ':userOwnerShip/:id', action: HttpMethods.PUT },
  getFranchisorDetails: { apiPath: 'franchisor_details/:id', action: HttpMethods.GET },
  getFranchisorsPermissionList: { apiPath: 'franchisor_permissions_lists/:id', action: HttpMethods.GET },
  updateFranchisorsPermissionList: { apiPath: 'franchisor_permissions_lists/:id', action: HttpMethods.PUT },
  locationGeocoder: { apiPath: 'mapbox.places', action: HttpMethods.GET },
  getFranchiseePermission: { apiPath: 'franchisee_permissions/:id', action: HttpMethods.GET },
  getLocIdsBasedOnHub: { apiPath: 'internal/franchisor_accounts', action: HttpMethods.GET },
  getLocIdsBasedOnLocList: { apiPath: 'account_list_details/:id', action: HttpMethods.GET },
  getFranSubscriptionTypes: { apiPath: 'franchisor_subscription_types', action: HttpMethods.GET },
  /* MEDIA GALLERY */
  mgGetPhotosAssetsList: { apiPath: 'photos', action: HttpMethods.GET },
  mgGetVideosAssetsList: { apiPath: 'videos', action: HttpMethods.GET },
  mgGetPhotosAssetsStats: { apiPath: 'photos/stats', action: HttpMethods.GET },
  mgGetVideosAssetsStats: { apiPath: 'videos/stats', action: HttpMethods.GET },
  mgGetDocumentsAssetsStats: { apiPath: 'documents/stats', action: HttpMethods.GET },
  mgDeletePost: { apiPath: 'assets/:id', action: HttpMethods.DELETE },
  mgGetAssetDetail: { apiPath: 'assets/:id', action: HttpMethods.GET },
  mgGetAccoutsTag: { apiPath: 'accounts/:id/favorite_tags', action: HttpMethods.GET },
  mgGetFavTags: { apiPath: `franchisors/:id/favorite_tags`, action: HttpMethods.GET },
  mgUpdateFavFrachisorTag: { apiPath: `franchisors/:id/favorite_tags`, action: HttpMethods.POST },
  mgGetStats: { apiPath: 'assets_stats', action: HttpMethods.GET },
  mgAddPhotoAsset: { apiPath: 'photos', action: HttpMethods.POST },
  mgAddVideoAsset: { apiPath: 'videos', action: HttpMethods.POST },
  mgDeletePhoto: { apiPath: 'photos/:id', action: HttpMethods.PUT },
  mgDeleteVideo: { apiPath: 'videos/:id', action: HttpMethods.PUT },
  mgMediaReleasePhoto: { apiPath: 'photos/:id/media_releases', action: HttpMethods.GET },
  mgMediaReleaseVideo: { apiPath: 'videos/:id/media_releases', action: HttpMethods.GET },
  mgLikePhoto: { apiPath: 'photos/:id/like', action: HttpMethods.POST },
  mgLikeVideo: { apiPath: 'videos/:id/like', action: HttpMethods.POST },
  mgLikeDocument: { apiPath: 'documents/:id/like', action: HttpMethods.POST },
  mgDislikePhoto: { apiPath: 'photos/:id/dislike', action: HttpMethods.POST },
  mgDislikeVideo: { apiPath: 'videos/:id/dislike', action: HttpMethods.POST },
  mgDislikeDocuments: { apiPath: 'documents/:id/dislike', action: HttpMethods.DELETE },
  mgGetPhotoAssetDetail: { apiPath: 'photos/:id', action: HttpMethods.GET },
  mgGetVideoAssetDetail: { apiPath: 'videos/:id', action: HttpMethods.GET },
  mgUpdatePhotoAssetDetail: { apiPath: 'photos/:id', action: HttpMethods.PUT },
  mgUpdateVideoAssetDetail: { apiPath: 'videos/:id', action: HttpMethods.PUT },
  mgClonePhoto: { apiPath: 'photos/:assetId/clone_photo', action: HttpMethods.GET },
  mgCloneVideo: { apiPath: 'videos/:assetId/clone_video', action: HttpMethods.GET },
  mgS3FileUpload: { apiPath: 'aws/upload', action: HttpMethods.POST },
  /* POSTS */
  getSavedPosts: { apiPath: 'saved_post_summaries', action: HttpMethods.GET },
  getPostsDetail: { apiPath: 'saved_posts/:id', action: HttpMethods.GET },
  getSavedPostComments: { apiPath: 'saved_posts/:id/saved_post_comments', action: HttpMethods.GET },
  addSavedPostComments: { apiPath: 'saved_posts/:id/saved_post_comments', action: HttpMethods.POST },
  getContentPosts: { apiPath: 'content_summaries', action: HttpMethods.GET },
  getContentPostDetail: { apiPath: 'contents/:id', action: HttpMethods.GET },
  deletePostContentRequest: { apiPath: 'scheduled_multiposts/:postId/accounts/:contentId', action: HttpMethods.DELETE },
  getPostsStats: { apiPath: 'posts_stats', action: HttpMethods.GET },
  getPostsContentsStats: { apiPath: 'contents_stats', action: HttpMethods.GET },
  getPostsTags: { apiPath: 'tags', action: HttpMethods.GET },
  getPostsTagsStats: { apiPath: 'tags/stats', action: HttpMethods.GET },
  deletePosts: { apiPath: 'saved_posts/:id', action: HttpMethods.PATCH },
  deleteAdminPosts: { apiPath: 'contents/:id', action: HttpMethods.DELETE },
  copyAdminPosts: { apiPath: 'contents/:id/copy', action: HttpMethods.POST },
  copyLocationPosts: { apiPath: 'saved_posts/:id/copy', action: HttpMethods.POST },
  saveDraftPost: { apiPath: 'saved_posts', action: HttpMethods.POST },
  saveContentDraftPost: { apiPath: 'contents', action: HttpMethods.POST },
  editDraftPost: { apiPath: 'saved_posts/:id', action: HttpMethods.PUT },
  editContentDraftPost: { apiPath: 'contents/:id', action: HttpMethods.PUT },
  editContentPostExpireDate: { apiPath: 'contents/:id', action: HttpMethods.PATCH },
  editSavedPostExpireDate: { apiPath: 'saved_posts/:id', action: HttpMethods.PATCH },
  getContentSummaryData: { apiPath: 'content_summaries/:id', action: HttpMethods.GET },
  getContentIntNotes: { apiPath: 'content_comments', action: HttpMethods.GET },
  addContentIntNotes: { apiPath: 'content_comments', action: HttpMethods.POST },
  getFranchisorSyndPost: { apiPath: 'contents/:content_id/franchisor_syndications', action: HttpMethods.GET },
  getFranchisorSyndPhoto: { apiPath: 'photos/:photo_id/franchisor_photo_syndications', action: HttpMethods.GET },
  getFranchisorSyndVideo: { apiPath: 'videos/:video_id/franchisor_video_syndications', action: HttpMethods.GET },
  addFranchisorSyndPost: { apiPath: 'contents/:content_id/franchisor_syndications', action: HttpMethods.POST },
  addFranchisorSyndPhoto: { apiPath: 'photos/:photo_id/franchisor_photo_syndications', action: HttpMethods.POST },
  addFranchisorSyndVideo: { apiPath: 'videos/:video_id/franchisor_video_syndications', action: HttpMethods.POST },
  getFranchisorSyndDocuments: { apiPath: 'documents/:document_id/franchisor_document_syndications', action: HttpMethods.GET },
  addFranchisorSyndDocuments: { apiPath: 'documents/:document_id/franchisor_document_syndications', action: HttpMethods.POST },
  getVideoCompatibility: { apiPath: 'videos/:id/compatibility', action: HttpMethods.GET },
  getHashtagsList: { apiPath: ':userOwnerShip/:id/hashtags', action: HttpMethods.GET },
  createHashtag: { apiPath: ':userOwnerShip/:id/hashtags', action: HttpMethods.POST },
  deleteHashtag: { apiPath: ':userOwnerShip/:id/hashtags', action: HttpMethods.DELETE },
  addFranchisorMultiSyndPost: { apiPath: 'contents/franchisor_multiple_syndications', action: HttpMethods.POST },
  addFranchisorMultiSyndPhoto: { apiPath: 'photos/franchisor_multiple_syndications', action: HttpMethods.POST },
  addFranchisorMultiSyndVideo: { apiPath: 'videos/franchisor_multiple_syndications', action: HttpMethods.POST },
  addFranchisorMultiSyndDocuments: { apiPath: 'documents/franchisor_multiple_syndications', action: HttpMethods.POST },
  addAccountMultiSyndPost: { apiPath: 'contents/account_multiple_syndications', action: HttpMethods.POST },
  getPostPerformanceRate: { apiPath: 'post_performance_rate', action: HttpMethods.GET },
  getSyndicationCountries: { apiPath: 'countries', action: HttpMethods.GET },
  getPreviousSyndications: { apiPath: 'franchisors/:id/previous_syndications', action: HttpMethods.GET },

  /* CAMPAIGN */
  campaignsList: { apiPath: 'campaigns', action: HttpMethods.GET },
  campaignStats: { apiPath: 'campaigns/stats', action: HttpMethods.GET },
  getCampaignEventList: { apiPath: 'campaigns/:campaignId/campaign_events', action: HttpMethods.GET },
  getCampaignEvent: { apiPath: 'campaigns/:campaignId/campaign_events/:eventId', action: HttpMethods.GET },
  createCampaignEvent: { apiPath: 'campaigns/:campaignId/campaign_events', action: HttpMethods.POST },
  updateCampaignEvent: { apiPath: 'campaigns/:campaignId/campaign_events/:campaignEventId', action: HttpMethods.PUT },
  deleteCampaignEvent: { apiPath: 'campaigns/:campaignId/campaign_events/:eventId', action: HttpMethods.DELETE },
  stopCampaignEvent: { apiPath: 'campaigns/:campaignId/campaign_events/:eventId/stop_event', action: HttpMethods.PUT },
  getCampaignDetail: { apiPath: 'campaigns/:campaignId', action: HttpMethods.GET },
  deleteCampaign: { apiPath: 'campaigns/:campaignId', action: HttpMethods.DELETE },
  deactivateCampaignEvent: { apiPath: 'campaigns/:campaignId/deactivate_campaign_event', action: HttpMethods.PUT },
  sortCampaignPosts: { apiPath: 'campaigns/:campaignId/posts_sorting', action: HttpMethods.POST },
  addPostsToCampaign: { apiPath: 'campaigns/:campaignId/add_posts', action: HttpMethods.POST },
  deleteCampaignPosts: { apiPath: 'campaigns/:campaignId/remove_post', action: HttpMethods.DELETE },
  createCampaign: { apiPath: 'campaigns', action: HttpMethods.POST },
  updateCampaign: { apiPath: 'campaigns/:campaignId', action: HttpMethods.PUT },
  /* CALENDAR */

  getScheduledPost: { apiPath: 'scheduled_posts', action: HttpMethods.GET },
  updateScheduledPost: { apiPath: 'scheduled_posts/:id', action: HttpMethods.PUT },
  deleteAutomatedScheduledPost: { apiPath: 'scheduled_posts/:id', action: HttpMethods.DELETE },

  getRequestedCalendarItem: { apiPath: 'calendar_items', action: HttpMethods.GET },
  updateRequestedCalendarItem: { apiPath: 'calendar_items/:id', action: HttpMethods.PUT },
  getDetailSchedulePost: { apiPath: 'scheduled_posts/:id', action: HttpMethods.GET },
  getSavedPost: { apiPath: 'saved_posts', action: HttpMethods.GET },
  updateSavedPost: { apiPath: 'saved_posts/:id', action: HttpMethods.PUT },
  updateSavedPostSingleField: { apiPath: 'saved_posts/:id', action: HttpMethods.PATCH },
  rejectSavedPost: { apiPath: 'saved_posts/:id/reject', action: HttpMethods.PUT },
  approvePost: { apiPath: 'saved_posts/:id/approve', action: HttpMethods.PUT },
  postPublishedStatus: { apiPath: 'scheduled_post_statuses', action: HttpMethods.GET },

  getScheduledMultiPost: { apiPath: 'scheduled_multiposts', action: HttpMethods.GET },
  updateSchduledMultiPost: { apiPath: 'scheduled_multiposts/:id', action: HttpMethods.PUT },
  deleteSchduledMultiPost: { apiPath: 'scheduled_multiposts/:id', action: HttpMethods.DELETE },
  /* ADVOCACY LEADERBOARD */
  advLbGetAssets: { apiPath: 'leaderboards/consolidate_advocacy_async', action: HttpMethods.GET },
  advLbGetFilterStats: { apiPath: 'leaderboards/stats', action: HttpMethods.GET },
  /* REWARDS PROGRAM */
  getTreeData: { apiPath: 'activate_brand_hubs_locations', action: HttpMethods.GET },
  /* OVERVIEW */
  overviewLocations: { apiPath: 'dashboard/locations_list', action: HttpMethods.GET },
  overviewTotalMetrics: { apiPath: 'dashboard/total_metrics_overview', action: HttpMethods.GET },
  overviewRecentPosts: { apiPath: 'dashboard/recent_posts', action: HttpMethods.GET },
  overviewPostsMetrics: { apiPath: 'dashboard/posts_metrics', action: HttpMethods.GET },
  overviewMonthlyPostsMetrics: { apiPath: 'dashboard/date_wise_post_metrics', action: HttpMethods.GET },
  overviewLocationGeocoder: { apiPath: 'mapbox.places', action: HttpMethods.GET },
  /* SETTINGS */
  settingsUploadImage: { apiPath: 'franchisors', action: HttpMethods.PATCH },
  /* ADVOCACY ONBOARD */
  tmReinviteForEachUser: { apiPath: 'users/:user_id/send_wizard', action: HttpMethods.POST },
  tmResendInviteForMultipleUsers: { apiPath: 'users/send_wizard', action: HttpMethods.POST },
  /** SETTINGS RALLIO PROFILE */
  accountsUpdateProfile: { apiPath: 'accounts/:id', action: HttpMethods.PUT },
  accountsGetProfile: { apiPath: 'accounts/:id', action: HttpMethods.GET },
  accountsAddProfile: { apiPath: 'accounts', action: HttpMethods.POST },
  /** GET TIME ZONES */
  getTimeZone: { apiPath: 'time_zones', action: HttpMethods.GET },
  /** GET COUNTRIES CODE */
  getCountriesCode: { apiPath: 'countries', action: HttpMethods.GET },

  /** SOCIAL CONTENTS */
  getSocialContentsList: { apiPath: 'social_contents', action: HttpMethods.GET },
  getSocialContentsStats: { apiPath: 'social_contents/stats', action: HttpMethods.GET },
  deleteSocialContent: { apiPath: 'social_contents/:id/delete_from_platform', action: HttpMethods.DELETE },
  socialContentsLike: { apiPath: 'social_contents/:id/like', action: HttpMethods.POST },
  socialContentsDisLike: { apiPath: 'social_contents/:id/dislike', action: HttpMethods.POST },
  socialContentsFavorite: { apiPath: 'social_contents/:id/favorite', action: HttpMethods.POST },
  socialContentsUnFavorite: { apiPath: 'social_contents/:id/unfavorite', action: HttpMethods.POST },
  socialContentsReuse: { apiPath: 'social_contents/:id/reuse', action: HttpMethods.POST },
  socialContentsReply: { apiPath: 'social_contents/:id/reply', action: HttpMethods.POST },
  socialContentsPieReload: { apiPath: 'accounts/:id/activate', action: HttpMethods.POST },
  getComments: { apiPath: 'social_contents/:id/comments', action: HttpMethods.GET },
  /** GET SANDBOX PEERS */
  getSandboxPeers: { apiPath: 'franchisors/:id/peers', action: HttpMethods.GET },
  /** GET INBOX,OUTBOX LOCATIONS */
  getSocialContentFranchisors: { apiPath: 'franchisor_selections', action: HttpMethods.GET },
  /** INBOX RETWEET */
  inboxRetweet: { apiPath: 'social_contents/:id/retweet', action: HttpMethods.POST },
  /** ADD POST */
  addScheduledPost: { apiPath: 'saved_posts/:id/scheduled_posts', action: HttpMethods.POST },

  getCouponList: { apiPath: 'rallio_coupons', action: HttpMethods.GET },
  getFacebookPageTagList: { apiPath: 'facebook_business_pages', action: HttpMethods.GET },
  getInstaPhotoPreview: { apiPath: 'instagram_photo_preview', action: HttpMethods.POST },
  addBoostForScheduledPost: { apiPath: 'facebook_boost_enduser_campaigns', action: HttpMethods.POST },
  /** ADD POST FRANCHISOR */
  getAccountList: { apiPath: 'account_lists', action: HttpMethods.GET },
  updateAccountList: { apiPath: 'account_lists/:id', action: HttpMethods.PUT },
  subscriptionAccountToList: { apiPath: 'account_lists/:account_list_id/join', action: HttpMethods.POST },
  deleteSubscriptionAccountToList: { apiPath: 'account_lists/:account_list_id/leave', action: HttpMethods.DELETE },
  deleteCustomLoc: { apiPath: 'account_lists/:id', action: HttpMethods.DELETE },
  getEligibleContentsCount: { apiPath: 'account_lists/eligible_contents_counts', action: HttpMethods.POST },
  getFranchisorList: { apiPath: 'franchisor_lists', action: HttpMethods.GET },
  getDefaultHubList: { apiPath: 'franchisor_list_details/new', action: HttpMethods.GET },
  getSelectedHubList: { apiPath: 'franchisor_list_details/:id', action: HttpMethods.GET },
  addCustomHubList: { apiPath: 'franchisor_list_details', action: HttpMethods.POST },
  updateCustomHubList: { apiPath: 'franchisor_list_details/:id', action: HttpMethods.PUT },
  getFranchisorDetail: { apiPath: 'franchisor_lists/:id', action: HttpMethods.GET },
  getDefaultLocList: { apiPath: 'account_list_details/new', action: HttpMethods.GET },
  getSelectedLocList: { apiPath: 'account_list_details/:id', action: HttpMethods.GET },
  addCustomLocList: { apiPath: 'account_list_details', action: HttpMethods.POST },
  updateCustomLocList: { apiPath: 'account_list_details/:id', action: HttpMethods.PUT },
  updateCustomHub: { apiPath: 'franchisor_lists/:id', action: HttpMethods.PUT },
  contentMassDelete: { apiPath: 'contents/:id/mass_delete', action: HttpMethods.PUT },
  getAccSelectionList: { apiPath: 'account_selections', action: HttpMethods.GET },
  addLink: { apiPath: 'links', action: HttpMethods.POST },
  localizePreviewsLink: { apiPath: 'links/personalized_url_previews', action: HttpMethods.GET },
  deleteLink: { apiPath: 'links/:id', action: HttpMethods.DELETE },
  updateLink: { apiPath: 'links/:id', action: HttpMethods.PUT },
  createLinkForCoupon: { apiPath: 'rallio_coupons/:id/create_link', action: HttpMethods.POST },
  addScheduledMultiposts: { apiPath: 'scheduled_multiposts', action: HttpMethods.POST },
  massUpdateForSchPost: { apiPath: 'contents/:id/mass_update', action: HttpMethods.PUT },
  getAddContentVerticals: { apiPath: 'verticals', action: HttpMethods.GET },
  createLinkForAccCoupon: { apiPath: 'rallio_coupons/:id/create_scheduled_post_link_attributes', action: HttpMethods.POST },
  /** INBOX DISMISS */
  inboxDismiss: { apiPath: 'social_contents/:id/comments/dismiss', action: HttpMethods.POST },
  /** INBOX PIE RELOAD */
  inboxPieReload: { apiPath: 'accounts/:id/activate', action: HttpMethods.POST },
  /** REVIEWS */
  getReviewsList: { apiPath: 'reviews', action: HttpMethods.GET },
  getReviewStats: { apiPath: 'reviews/stats', action: HttpMethods.GET },
  sendReplyForPost: { apiPath: 'reviews/:id/reply', action: HttpMethods.POST },
  completeReviewPost: { apiPath: 'reviews/:id/complete', action: HttpMethods.POST },
  getReviewsInternalNotes: { apiPath: 'reviews/:id/internal_notes', action: HttpMethods.GET },
  addReviewsInternalNote: { apiPath: 'reviews/:id/internal_notes', action: HttpMethods.POST },
  handleReviewPost: { apiPath: 'reviews/:id/handle', action: HttpMethods.POST },
  reviewsSendForReplyApproval: { apiPath: 'reviews/:id/request_reply_approval', action: HttpMethods.POST },
  updateSubmittedReply: { apiPath: 'reviews/:id/update_submitted_reply', action: HttpMethods.PUT },
  approveReply: { apiPath: 'reviews/:id/approve_reply', action: HttpMethods.POST },
  rejectReply: { apiPath: 'reviews/:id/reject_reply', action: HttpMethods.POST },
  deleteReply: { apiPath: 'reviews/:id/delete_reply', action: HttpMethods.DELETE },
  /** DIRECTORY LISTINGS */
  getDLSynupSubCategories: { apiPath: 'accounts/synup_sub_categories', action: HttpMethods.GET },
  getDLBusinessProfileCategories: { apiPath: 'accounts/business_profile_categories', action: HttpMethods.GET },
  getDLSynupListing: { apiPath: 'accounts/synup_listings', action: HttpMethods.GET },
  updateDL: { apiPath: 'accounts/:id/update_directory_listings', action: HttpMethods.POST },
  /** USER LOGINS */
  getULAccountOwners: { apiPath: 'accounts/:id/account_owners', action: HttpMethods.GET },
  addULAccountOwners: { apiPath: 'accounts/:id/account_owners', action: HttpMethods.POST },
  // updateULAccountOwners: { apiPath: 'accounts/:id/account_owners', action: HttpMethods.PUT },
  updateULAccountOwnersFeatures: { apiPath: 'accounts/:id/account_owners/:accountOwnerId', action: HttpMethods.PUT },
  accountOwnersCheckEmail: { apiPath: 'accounts/:id/account_owners/check_email', action: HttpMethods.GET },
  deleteAccountOwner: { apiPath: 'accounts/:accountId/account_owners/:ownerId', action: HttpMethods.DELETE },
  resendIniviteToUser: { apiPath: 'users/:id/send_wizard', action: HttpMethods.POST },
  /** CONNECTIONS */
  getConnectionsList: { apiPath: 'franchisors/:id/account_connection_grid_rows', action: HttpMethods.GET },
  /** PROFILE IMAGERY */
  getProfileImageryList: { apiPath: 'internal/account_pictures', action: HttpMethods.GET },
  updateProfileImageryPicture: { apiPath: 'internal/account_pictures/change', action: HttpMethods.POST },
  getFilterProfileImageryList: { apiPath: 'photos', action: HttpMethods.GET },
  /** FB ADS TARGETING */
  getFBAdsFranChoices: { apiPath: 'franchisors/:id/franchisor_facebook_ad_account_choice', action: HttpMethods.GET },
  updateFBAdsFranChoices: { apiPath: 'franchisors/:id/franchisor_facebook_ad_account_choice', action: HttpMethods.POST },
  getFBAdsAccChoices: { apiPath: 'accounts/:id/facebook_ad_account_choice', action: HttpMethods.GET },
  updateFBAdsAccChoices: { apiPath: 'accounts/:id/facebook_ad_account_choice', action: HttpMethods.POST },
  disconnectFBAdsFranChoices: { apiPath: 'franchisors/:id/franchisor_facebook_ad_account_choice', action: HttpMethods.DELETE },
  disconnectFBAdsAccChoices: { apiPath: 'accounts/:id/facebook_ad_account_choice', action: HttpMethods.DELETE },
  getAccTargetingInterests: { apiPath: 'accounts/:id/facebook_ad_account_choice/targeting_interests', action: HttpMethods.GET },
  getFranTargetingInterests: { apiPath: 'franchisors/:id/facebook_ad_account_configuration/targeting_interests', action: HttpMethods.GET },
  updateAccDefaultConfig: { apiPath: 'accounts/:id/facebook_ad_account_choice/default_configuration', action: HttpMethods.PUT },
  getFBAdAccountList: { apiPath: 'facebook_user_credentials', action: HttpMethods.GET },
  getFBAdSingleAccount: { apiPath: 'facebook_user_credentials/:id', action: HttpMethods.GET },
  getFBAdSingleAccountId: { apiPath: 'facebook_user_credentials/:id/ad_accounts', action: HttpMethods.GET },
  getFBAdAccountPageList: { apiPath: 'facebook_user_credentials/:id/facebook_page_credentials', action: HttpMethods.GET },
  getFBAdAccountPageByUrl: { apiPath: 'facebook_user_credentials/:id/facebook_page_credentials', action: HttpMethods.POST },
  getInstagramPageList: { apiPath: 'facebook_user_credentials/:id/instagram_business_accounts', action: HttpMethods.GET },
  saveUserCredentials: { apiPath: 'facebook_user_credentials', action: HttpMethods.POST },
  connectInstagramSocialMedia: { apiPath: 'accounts/:id/connect_instagram_business_account', action: HttpMethods.POST },
  addFBAdPreview: { apiPath: 'facebook_ad_previews', action: HttpMethods.POST },
  getAverageLocationsSpend: { apiPath: 'franchisors/:id/facebook_ad_account_configuration/spends', action: HttpMethods.GET },
  getAccConfigReachEstimate: { apiPath: 'franchisors/:id/facebook_ad_account_configuration/account_configurations/:accountId/reach_estimate', action: HttpMethods.PUT },
  /** REVIEW ANALYTICS */
  getReviewAnalytics: { apiPath: 'reviews/analytics', action: HttpMethods.GET },
  getReviewAnalyticsSummary: { apiPath: 'reviews/analytics_summary', action: HttpMethods.GET },
  getReviewKeyWord: { apiPath: 'reviews/analytics_keywords', action: HttpMethods.GET },
  getAllAvailableReviewKeyWord: { apiPath: 'review_keyword_categories/available_words', action: HttpMethods.GET },
  /** KEYWORDS CATEGORY */
  getKeywordsCategoryList: { apiPath: 'review_keyword_categories', action: HttpMethods.GET },
  getKeywordsCategory: { apiPath: 'review_keyword_categories/:id', action: HttpMethods.GET },
  createKeywordsCategory: { apiPath: 'review_keyword_categories', action: HttpMethods.POST },
  updateKeywordsCategory: { apiPath: 'review_keyword_categories/:id', action: HttpMethods.PUT },
  deleteKeywordsCategory: { apiPath: 'review_keyword_categories/:id', action: HttpMethods.DELETE },
  getKeywordCategoriesAnalytics: { apiPath: 'accounts/:account_id/review_keyword_categories_enduser_analytics', action: HttpMethods.POST },
  /** PROFILE ANALYTICS */
  getProfileAnalytics: { apiPath: 'profile_analytics', action: HttpMethods.GET },
  /** LEADERBOARD ANALYTICS */
  getLeaderBoardAnalytics: { apiPath: 'franchisors/:id/analytics_table', action: HttpMethods.GET },
  getCSLeaderBoardAnalytics: { apiPath: 'franchisors/:id/content_writer_analytics_leaderboard', action: HttpMethods.GET },
  getReviewCategoryLeaderBoardAnalytics: { apiPath: 'franchisors/:id/review_keyword_categories_leaderboard', action: HttpMethods.GET },
  getReviewCategoryIdLeaderBoardAnalytics: { apiPath: 'review_keyword_category_ids', action: HttpMethods.GET },
  /** SOCIAL ANALYTICS */
  getSocialAnalytics: { apiPath: 'social_analytics', action: HttpMethods.GET },
  getSocialAnalyticsSummary: { apiPath: 'social_analytics_summary', action: HttpMethods.GET },
  getCSSocialAnalyticsSummary: { apiPath: 'franchisors/:id/content_writer_social_analytics', action: HttpMethods.GET },
  /** CONTENT ANALYTICS */
  getContentAnalytics: { apiPath: 'accounts/:id/post_analytics', action: HttpMethods.GET },
  getHubContentAnalytics: { apiPath: 'content_filter_stats', action: HttpMethods.GET },
  getHubMultipleLocationsContentAnalytics: { apiPath: 'content_filter_stats/:id/post_stats', action: HttpMethods.GET },
  getLocalPostContentAnalytics: { apiPath: 'enduser_filter_post_stat_rows', action: HttpMethods.GET },
  getHubModalDataContentAnalytics: { apiPath: 'contents/:id', action: HttpMethods.GET },
  getLocalModalDataContentAnalytics: { apiPath: 'accounts/userId/post_analytics/:id', action: HttpMethods.GET },
  /** SOCIAL SETTINGS */
  saveSocialMediaSetting: { apiPath: 'social_media', action: HttpMethods.POST },
  updateSocialMediaSetting: { apiPath: 'social_media/:id', action: HttpMethods.PUT },
  deleteSocialMediaSetting: { apiPath: 'social_media/:id', action: HttpMethods.DELETE },
  connectInstagram: { apiPath: 'accounts/:id/connect_instagram', action: HttpMethods.POST },
  disconnectInstagram: { apiPath: 'accounts/:id/disconnect_instagram', action: HttpMethods.POST },
  /** COUPONS */
  getCouponsList: { apiPath: 'rallio_coupons', action: HttpMethods.GET },
  addCoupon: { apiPath: 'rallio_coupons', action: HttpMethods.POST },
  updateCoupon: { apiPath: 'rallio_coupons/:id', action: HttpMethods.PUT },
  getCouponDetail: { apiPath: 'rallio_coupons/:id', action: HttpMethods.GET },
  deleteCoupon: { apiPath: 'rallio_coupons/:id', action: HttpMethods.DELETE },
  /** USER */
  getUserDetail: { apiPath: 'users/:id', action: HttpMethods.GET },
  saveNotificationSettings: { apiPath: 'users/:id', action: HttpMethods.PUT },
  impersonateUser: { apiPath: 'users/:id/become', action: HttpMethods.GET },
  /** CHANGE PASSWORD */
  changePassword: { apiPath: 'users/:id/update_password', action: HttpMethods.PUT },
  /** ADMIN AREA */
  getAccountSummaries: { apiPath: 'account_summaries', action: HttpMethods.GET },
  getFranchisorsList: { apiPath: 'franchisors', action: HttpMethods.GET },
  addNewFranchisor: { apiPath: 'franchisors', action: HttpMethods.POST },
  getFranchisorReport: { apiPath: 'franchisors/:id/accounts_report', action: HttpMethods.GET },
  getUsersList: { apiPath: 'users', action: HttpMethods.GET },
  updateFranchisor: { apiPath: 'franchisors/:id', action: HttpMethods.PUT },
  getFranchisor: { apiPath: 'franchisors/:id', action: HttpMethods.GET },
  getExpirtStats: { apiPath: 'accounts/expiry_stats', action: HttpMethods.GET },
  adminAreaActions: { apiPath: 'internal/admin_actions/synchronize_hootsuite', action: HttpMethods.POST },
  loadWatermarkImage: { apiPath: 'watermark_photos', action: HttpMethods.GET },
  createWatermarkImage: { apiPath: 'watermark_photos', action: HttpMethods.POST },
  updateWatermarkImage: { apiPath: 'watermark_photos/:id', action: HttpMethods.PUT },
  /** ADMIN AREA */
  rssFeedsList: { apiPath: 'rss_feed/news', action: HttpMethods.POST },
  getGoogleBusinessAccounts: { apiPath: 'google_oauth2_credentials/:id/google_my_business_accounts', action: HttpMethods.GET },
  getGoogleAccountLocations: { apiPath: 'google_oauth2_credentials/:id/google_my_business_accounts/:accountId/google_my_business_locations', action: HttpMethods.GET },
  connectGoogle: { apiPath: 'accounts/:id/connect_google', action: HttpMethods.POST },
  targetingList: { apiPath: 'franchisors/:id/facebook_ad_account_configuration', action: HttpMethods.GET },
  updateTargetingConfiguration: { apiPath: 'franchisors/:id/facebook_ad_account_configuration/account_configurations/:accountId', action: HttpMethods.PUT },
  getTargetingConfiguration: { apiPath: 'franchisors/:id/facebook_ad_account_configuration/account_configurations/:accountId', action: HttpMethods.GET },
  /** SALES */
  scheduleDemoContact: { apiPath: 'schedule_demo_contact', action: HttpMethods.POST },
  updateUserDetail: { apiPath: 'users/:id', action: HttpMethods.PATCH },
  // AI PLAYBOOK DOCUMENTS
  getAIPlaybookDocumentsList: { apiPath: 'documents', action: HttpMethods.GET },
  getAIPlaybookDocumentDetail: { apiPath: 'documents/:id', action: HttpMethods.GET },
  createAIPlaybookDocument: { apiPath: 'documents', action: HttpMethods.POST },
  deleteAIPlaybookDocument: { apiPath: 'documents/:id', action: HttpMethods.DELETE },
  updateDocumentDetail: { apiPath: 'documents/:id', action: HttpMethods.PUT },
  // AI CAPTIONS LIST
  getAICaptionsList: { apiPath: 'captions', action: HttpMethods.GET },
  createAICaptionsList: { apiPath: 'captions', action: HttpMethods.POST },
  updateAICaptionsList: { apiPath: 'captions/:id', action: HttpMethods.PUT },
  // AI STABLE DIFFUSION IMAGE GENERATIONS
  aiStableDiffusionImageGenerations: { apiPath: 'automatic_tasks', action: HttpMethods.POST },
  getAIStableDiffusionImageGenerations: { apiPath: 'automatic_tasks/:id', action: HttpMethods.GET },
  // SETTINGS AI PLAYBOOK
  getActivityGoalsList: { apiPath: 'activity_goals', action: HttpMethods.GET },
  createActivityGoals: { apiPath: 'activity_goals', action: HttpMethods.POST },
  updateActivityGoals: { apiPath: 'activity_goals/:id', action: HttpMethods.PUT },
  deleteActivityGoals: { apiPath: 'activity_goals/:id', action: HttpMethods.DELETE },
  getActivityGoalTypes: { apiPath: 'activity_goal_types', action: HttpMethods.GET },
  // COMMUNICATIONS
  getBrandLevelCommunicationsList: { apiPath: 'franchisors/:id/communications', action: HttpMethods.GET },
  getLocationLevelCommunicationsList: { apiPath: 'accounts/:id/communications', action: HttpMethods.GET },
  createCommunication: { apiPath: 'franchisors/:franchisor_id/communications', action: HttpMethods.POST },
  updateCommunication: { apiPath: 'communications/:id', action: HttpMethods.PUT },
  deleteCommunication: { apiPath: 'communications/:id', action: HttpMethods.DELETE },
  markAsSeenCommunication: { apiPath: 'communications/:id/mark_as_seen', action: HttpMethods.PUT },
  // CONTENT SUPPLIER
  getContentSupplierFranchisor: { apiPath: 'franchisors/:id/content_supplier_franchisors', action: HttpMethods.GET },
  getContentSupplierCorpFranchisor: { apiPath: 'franchisors/:id/content_supplier_corporate_franchisors', action: HttpMethods.GET },
  // ACCOUNT SCHEDULER
  getAccountSchedulerList: { apiPath: 'accounts/:account_id/account_list_schedules', action: HttpMethods.GET },
  createAccountSchedulerList: { apiPath: 'accounts/:account_id/account_list_schedules', action: HttpMethods.POST },
  updateAccountSchedulerList: { apiPath: 'account_list_schedules/:id', action: HttpMethods.PUT },
  deleteAccountSchedulerList: { apiPath: 'account_list_schedules/:id', action: HttpMethods.DELETE },
  blockAccountSchedulerList: { apiPath: 'account_list_schedules/:id/blocklist', action: HttpMethods.POST },
  createAccountScheduledPost: { apiPath: 'accounts/:account_id/account_list_schedules/create_scheduled_posts', action: HttpMethods.POST },
  makeAccountDefaultsScheduler: { apiPath: 'accounts/:account_id/account_list_schedules/create_defaults', action: HttpMethods.POST },
  getAccountSchedulerPostStats: { apiPath: 'accounts/:account_id/account_list_schedules/stats', action: HttpMethods.GET },
  // CONTENT SUPPLIER - SCHEDULER
  getFranchisorSchedulerList: { apiPath: 'franchisors/:franchisor_id/franchisor_account_list_schedules', action: HttpMethods.GET },
  createFranchisorSchedulerList: { apiPath: 'franchisors/:franchisor_id/franchisor_account_list_schedules', action: HttpMethods.POST },
  updateFranchisorSchedulerList: { apiPath: 'franchisor_account_list_schedules/:id', action: HttpMethods.PUT },
  deleteFranchisorSchedulerList: { apiPath: 'franchisor_account_list_schedules/:id', action: HttpMethods.DELETE },
  createFranchisorScheduledPost: { apiPath: 'franchisors/:franchisor_id/franchisor_account_list_schedules/create_scheduled_posts', action: HttpMethods.POST },
  // ADS BANNER
  getPartnerBanners: { apiPath: 'franchisors/:id/partner_banners', action: HttpMethods.GET },
  createPartnerBanner: { apiPath: 'franchisors/:id/partner_banners', action: HttpMethods.POST },
  updatePartnerBanner: { apiPath: 'franchisors/:id/partner_banners/:partner_banner_id', action: HttpMethods.PATCH },
  // BANNER PARTNER
  getPartnersBannerList: { apiPath: 'franchisors/:id/partners', action: HttpMethods.GET },
  enrollPartnerBanner: { apiPath: 'franchisors/:id/partner_syndication', action: HttpMethods.PUT },
  getPartnerBannerFranchisors: { apiPath: 'franchisors/:id/banner_partner_corporate_franchisors', action: HttpMethods.GET },
  getFacebookPhotoPreview: { apiPath: 'facebook_photo_preview', action: HttpMethods.POST },
  // AI REVIEW RESPONDER
  getFranchisorReviewResponderConfiguration: { apiPath: 'franchisors/:id/review_auto_reply_configuration', action: HttpMethods.GET },
  getLocationReviewResponderConfiguration: { apiPath: 'accounts/:id/review_auto_reply_configuration', action: HttpMethods.GET },
  updateFranchisorReviewResponderConfiguration: { apiPath: 'franchisors/:id/review_auto_reply_configuration', action: HttpMethods.PUT },
  updateLocationReviewResponderConfiguration: { apiPath: 'accounts/:id/review_auto_reply_configuration', action: HttpMethods.PUT },
  validatePostPersonalization: { apiPath: 'accounts/:id/database_personalizations_required', action: HttpMethods.GET },
  // TAGS MASS UPDATE
  updateMultiSelectPhotoTags: { apiPath: 'photos/mass_update_tags_lists', action: HttpMethods.POST },
  updateMultiSelectVideoTags: { apiPath: 'videos/mass_update_tags_lists', action: HttpMethods.POST },
  updateMultiSelectDocumentTags: { apiPath: 'documents/mass_update_tags_lists', action: HttpMethods.POST },
  updateMultiSelectContentTags: { apiPath: 'contents/mass_update_tags_lists', action: HttpMethods.POST },
  // AUTO REPLY CONFIGURATIONS
  getAutoReplyAccountPermissions: { apiPath: 'franchisors/:id/review_auto_reply_configuration/account_permissions', action: HttpMethods.GET },
  getFranchisorAIReviewSettingsStats: { apiPath: 'franchisors/:id/review_auto_reply_configuration/stats', action: HttpMethods.GET },
  getAccountAIReviewSettingsStats: { apiPath: 'accounts/:id/review_auto_reply_configuration/stats', action: HttpMethods.GET },
  ssoUpdateAccountSwitchToBrand: { apiPath: 'accounts/:id/move_from_rallio_travel_to_virtuoso', action: HttpMethods.POST },
  // SETTINGS HASHTAGS
  getFranchisorHashtagsList: { apiPath: 'franchisors/:id/saved_hashtags', action: HttpMethods.GET },
  getAccountHashtagsList: { apiPath: 'accounts/:id/saved_hashtags', action: HttpMethods.GET },
  updateFranchisorHashtagsList: { apiPath: 'franchisors/:id/saved_hashtags', action: HttpMethods.POST },
  updateAccountHashtagsList: { apiPath: 'accounts/:id/saved_hashtags', action: HttpMethods.POST }
};

export const NODE_API = {
  /*REWARDS PROGRAM*/
  getRpListData: { apiPath: 'reward_programs', action: HttpMethods.GET },
  getRpStatsData: { apiPath: 'reward_programs_stats', action: HttpMethods.GET },
  // getRpActionRewardsList: { apiPath: "rewards_list", action: HttpMethods.GET },
  getRpProgramData: { apiPath: 'reward_programs/:id', action: HttpMethods.GET },
  getRpAchieversListData: { apiPath: 'reward_programs/:id/achievers_list', action: HttpMethods.GET },
  getUserRewardsListData: { apiPath: 'reward_programs/:userId/user_rewards_list', action: HttpMethods.GET },
  deleteRpList: { apiPath: 'reward_programs', action: HttpMethods.DELETE },
  postRpProgram: { apiPath: 'reward_programs', action: HttpMethods.POST },
  updateRpPogram: { apiPath: 'reward_programs', action: HttpMethods.PUT },
  rewardPayment: { apiPath: 'reward_payment', action: HttpMethods.POST },
  rewardResendEmail: { apiPath: 'reward_programs/send_notification_email', action: HttpMethods.POST },
  /* ADVOCACY ONBOARD */
  tmAdvocatesList: { apiPath: 'community_management', action: HttpMethods.GET },
  tmAdvocatesStats: { apiPath: 'community_management_stats', action: HttpMethods.GET },
  getLinkedInPages: { apiPath: 'social_connections/linkedin/getPages', action: HttpMethods.GET },
  saveFbUserCredentials: { apiPath: 'social_connections/facebook/saveUser', action: HttpMethods.POST },
  saveFbPageCredentials: { apiPath: 'social_connections/facebook/savePage', action: HttpMethods.POST },
  updateFbAccessToken: { apiPath: 'social_connections/facebook/updateAccessToken', action: HttpMethods.POST },
  saveGoogleOauth: { apiPath: 'social_connections/google/saveOauth', action: HttpMethods.POST },
  getEnv: { apiPath: 'env', action: HttpMethods.GET },
  /** UPLOAD VIDEO TO CLOUDINARY */
  videoUpload: { apiPath: 'video_upload', action: HttpMethods.POST },
  /** AI CONTENT CEATOR */
  // aiContentCreator: { apiPath: 'rallio_ai/openai', action: HttpMethods.POST },
  aiReviewCreator: { apiPath: 'rallio_ai/ai_reviews_generation', action: HttpMethods.POST },
  aiCaptionCreator: { apiPath: 'rallio_ai/ai_caption_generation', action: HttpMethods.POST },
  aiTone: { apiPath: 'rallio_ai/ai_tone', action: HttpMethods.GET },
  aiModel: { apiPath: 'rallio_ai/ai_model', action: HttpMethods.GET },
  aiPost: { apiPath: 'rallio_ai/ai_post', action: HttpMethods.POST },
  aiTagsList: { apiPath: 'rallio_ai/ai_tags', action: HttpMethods.GET },
  aiSubsciptionPlan: { apiPath: 'rallio_ai/ai_subscription_plan', action: HttpMethods.GET },
  createAISubsciptionPlan: { apiPath: 'rallio_ai/ai_subscription_plan', action: HttpMethods.POST },
  updateAISubsciptionPlan: { apiPath: 'rallio_ai/ai_subscription_plan', action: HttpMethods.PUT },
  aiModelLearning: { apiPath: 'rallio_ai/ai_model_learning', action: HttpMethods.POST },
  aiImageGeneration: { apiPath: 'rallio_ai/ai_generate_image', action: HttpMethods.POST },
  validateAIImage: { apiPath: 'rallio_ai/ai_image_validation', action: HttpMethods.POST },
  // CUSTOMER PERSONA
  createCustomerPersona: { apiPath: 'rallio_ai/ai_customer_persona', action: HttpMethods.POST },
  // AI ASAssistant
  createAIAssistant: { apiPath: 'rallio_ai/ai_assistants', action: HttpMethods.POST },
  getAIAssistantChatHistory: { apiPath: 'rallio_ai/ai_assistants', action: HttpMethods.GET },
  deleteAIAssistants: { apiPath: 'rallio_ai/ai_assistants', action: HttpMethods.DELETE },
  validateAICaption: { apiPath: 'rallio_ai/ai_caption_validation', action: HttpMethods.POST },
  updateAILimitation: { apiPath: 'rallio_ai/ai_limitations', action: HttpMethods.POST },
  translateCaption: { apiPath: 'rallio_ai/ai_translate', action: HttpMethods.POST },
  getPromotionsList: { apiPath: 'promotionvault/promotions_list', action: HttpMethods.GET },
  /*STRIPE API*/
  searchByEmail: { apiPath: 'stripe/search_customer', action: HttpMethods.GET },
  allCardsForCustomer: { apiPath: 'stripe/retrieve_source', action: HttpMethods.GET },
  // REVIEW RESPONDER
  generateAIAutoReviews: { apiPath: 'rallio_ai/ai_auto_reviews_generation', action: HttpMethods.POST },
  // COMPLIANCE SUMMARY
  generateCaptionComplianceSummary: { apiPath: 'rallio_ai/ai_compliance_summary', action: HttpMethods.POST },
  generateImageComplianceSummary: { apiPath: 'rallio_ai/ai_image_compliance_summary', action: HttpMethods.POST }
};

export const OUTH_PUBLIC_API = {
  validateUser: { apiPath: 'users', action: HttpMethods.POST },
  getUserSignOnToken: { apiPath: 'users/:userId/sign_on_tokens', action: HttpMethods.POST }
};

export const FACEBOOK_API = {
  getPages: { apiPath: ':id/accounts', action: HttpMethods.GET }
};

export const PROMOTION_VAULT_API = {
  /*PROMOTION VAULT*/
  getPromotionsList: { apiPath: 'b2b/promotions', action: HttpMethods.GET }
};

export const STRIPE_API = {
  /*STRIPE API*/
  searchByEmail: { apiPath: 'search', action: HttpMethods.GET },
  allCardsForCustomer: { apiPath: 'customers/:id/sources', action: HttpMethods.GET }
};

export const CLOUDINARY_API = {
  /* UPLOAD IMAGE IN CLOUDINARY */
  // getSingatureForEager:{ apiPath: 'videos/signed_upload_fields', action: HttpMethods.GET },
  getSignature: { apiPath: 'assets/signed_upload_fields', action: HttpMethods.GET },
  cloudinaryImageUpload: { apiPath: '/upload', action: HttpMethods.POST }
};

export const REVV_API = {
  /* REVV */
  revvLogin: { apiPath: '/users/sign_in.json', action: HttpMethods.POST }
};
