import { useSelector } from 'react-redux';

import {
  addPostActionsResetAll,
  addPostFranResetAll,
  addPostMassUpdateForScheduledPostRequest,
  addPostResetAll,
  addPostUpdateContentRequest,
  addPostUpdateLinkRequest,
  addPostUpdateSavedPostDetails,
  addPostUpdateSavedPostRequest,
  calendarDeleteModalOption,
  calendarEditView,
  calendarEnableDatePicker,
  calendarEnableSchedule,
  commonSetContentModifiedStatus,
  deleteScheduledMultiPostRequest,
  postsResetAll,
  updateFBBoostConfigData,
  updateScheduledPostRequest
} from 'actions';
import { BrandSchedulePostModal, CreateOrEditPostModal } from 'components';
import { AP_CONTENT_ACTION_TYPE, CALENDAR_PAGE_VIEW_TYPES, CommonAction, RIPPLE_COLOR, USER_OWNERSHIP } from 'utils/constants';
import {
  canEditScheduledPostDateAndTime,
  dateHumanizer,
  dateObjBasedOnTimeZone,
  getApUpdatePostActionReqPayload,
  getCalendarSchReqPayload,
  getDiffInDate,
  getInitialLocationScheduleType,
  getLinkDataReqPayload,
  getPreviewBrandHubCreatorActions,
  getPreviewSocialMediaList,
  getReqPayloadForUpdateContent,
  utcTimeStampConverter
} from 'utils/helpers';
import { CustomRippleButton } from 'widgets/CustomRippleButton';
import { CalendarDeleteAlertModal } from './CalendarDeleteAlertModal';
import { CalendarDatePicker } from './CalendarDatePickerModal';
import { IStore, calendarContainerTypes } from 'types';
import { useAccountSwitcherData } from 'utils/hooks';

export const ContentCalendarListModals = ({
  calendarDateRange,
  editData,
  activePlatforms,
  currentPageView,
  isHubUser,
  handleOnChangePostContent,
  dispatch
}: calendarContainerTypes.IContentCalendarListModalsProps) => {
  const { userOwnership, id } = useAccountSwitcherData();

  const linkData = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj.linkData);
  const contentData = useSelector((state: IStore) => state.addPostFranchisor.contentData);
  const activeUserId = useSelector((state: IStore) => state.login.activeUser?.id);
  const linkIndex = useSelector((state: IStore) => state.addPostFranchisor.contentObj.linkIndex);
  const isThumbnailShow = useSelector((state: IStore) => state.addPostFranchisor.contentObj.isThumbnailShow);
  const contentObj = useSelector((state: IStore) => state.addPostFranchisor.contentObj);
  const isAiPost = useSelector((state: IStore) => state.aiContentCreator.isAiPost);
  const selectedLocData = useSelector((state: IStore) => state.addPostFranchisorAction.selectedLocData);
  const selectedMediaList = useSelector((state: IStore) => state.addPostFranchisor.selectedMediaList);
  const selectedFBTagList = useSelector((state: IStore) => state.addPostFranchisor.selectedFBTagList);
  const linkObj = useSelector((state: IStore) => state.addPostFranchisorAction.linkObj);
  const postStatus = useSelector((state: IStore) => state.addPostFranchisor.contentObj.postStatus);
  const locationsCount = useSelector((state: IStore) => state.accountSwitcher.childCount);
  const corporateAccountId = useSelector((state: IStore) => state.franchisors.franchisorDetails?.corporate_account_id || null);
  const isScheduleEnabled = useSelector((state: IStore) => state.calendar.isScheduleEnabled);
  const selectedObj = useSelector((state: IStore) => state.calendar.selectedDataObj);
  const contentModified = useSelector((state: IStore) => state.common.contentModified);
  const datePickerEnabled = useSelector((state: IStore) => state.calendar.datePickerEnabled);
  const deleteModal = useSelector((state: IStore) => state.calendar.deleteModal);
  const locationUpdateStates = useSelector((state: IStore) => state.calendar.locationUpdates);
  const accountDetails = useSelector((state: IStore) => state.accounts.accountDetails);
  const reducerTextEditorState = useSelector((state: IStore) => state.addPostFranchisorAction.textEditorState);
  const isFranchisee = useSelector((state: IStore) => state.accounts.accountDetails?.account_profiles[0]?.is_franchisee || false);
  const overriddenCorporatePostsLocked = useSelector((state: IStore) => state.accounts.permissions.franchiseePermissions?.overridden_corporate_posts_locked || false);

  const { selectedTags, instaPhotoPreviewObj, savedPostDetails, editSavedPost } = useSelector((state: IStore) => state.addPostAccount);

  const { actionType: instaType } = instaPhotoPreviewObj;
  const isEditEnabled = useSelector((state: IStore) => state.calendar.editableData.isEditEnabled);

  const dateDifference = getDiffInDate(editData?.scheduledTime || '');
  const humanReadableDate = dateHumanizer(dateDifference);

  const handleContentMassUpdate = () => {
    if (contentData?.id) {
      dispatch(addPostMassUpdateForScheduledPostRequest(contentData?.id));
    }
  };

  const editModalCloseHandler = () => {
    dispatch(calendarEditView({ isEditEnabled: false, editId: 0, editData: null }));
  };

  const resetDispatchHandler = () => {
    if (contentModified) dispatch(commonSetContentModifiedStatus(false));
    dispatch(postsResetAll());
    dispatch(addPostResetAll());
    dispatch(addPostFranResetAll());
    dispatch(addPostActionsResetAll());
    dispatch(updateFBBoostConfigData(null));
  };

  // HUB USER EDIT-UPDATE HANDLER
  const handleUpdateContentData = (actionName: string) => {
    if (linkData?.id && linkData?.link_preview_image_url_choices?.length > 1) {
      dispatch(addPostUpdateLinkRequest({ linkId: linkData.id, reqPayload: getLinkDataReqPayload(linkData, linkIndex, isThumbnailShow) }));
    }
    if (contentData?.id && activeUserId && actionName) {
      const saveDraftReqPayload = getReqPayloadForUpdateContent(
        id,
        activeUserId,
        contentData?.created_at || '',
        contentObj,
        selectedMediaList,
        selectedTags,
        selectedFBTagList,
        actionName,
        selectedLocData,
        linkData,
        instaType,
        [],
        contentData.content_origins,
        reducerTextEditorState?.facebook,
        contentData?.ai_post || isAiPost
      );
      dispatch(
        addPostUpdateContentRequest({
          actionType: actionName,
          postId: contentData.id,
          reqPayload: saveDraftReqPayload,
          franchisorId: id,
          ...(calendarDateRange.startDate &&
            calendarDateRange.endDate && {
              dateRange: { startDate: utcTimeStampConverter(calendarDateRange.startDate), endDate: utcTimeStampConverter(calendarDateRange.endDate) }
            }),
          refetch: true
        })
      );
    }
    editModalCloseHandler();
    resetDispatchHandler();
  };

  // HUB UPDATES -> REMOVE
  const hubPostDeleteHandler = (datum: calendarContainerTypes.ICalendarDataType) => {
    dispatch(deleteScheduledMultiPostRequest({ postId: datum?.id, contentId: datum?.contentId }));
    dispatch(calendarDeleteModalOption({ isOpenDeleteModal: false, isConfirmDelete: false }));
  };

  // LOCATION USER EDIT-UPDATE HANDLER
  const handleUpdateSavedPostData = (actionType: string, eventId?: number, refetch?: boolean) => {
    let reqPayload = null;
    if (id && userOwnership && savedPostDetails && editSavedPost?.id) {
      reqPayload = getApUpdatePostActionReqPayload(
        editSavedPost,
        savedPostDetails,
        selectedFBTagList,
        selectedMediaList,
        selectedTags,
        linkObj.linkData,
        actionType,
        instaType,
        reducerTextEditorState?.facebook,
        [],
        false,
        editSavedPost?.ai_post || isAiPost
      );
      if (editSavedPost?.id === editData?.savedPostId) dispatch(addPostUpdateSavedPostRequest({ actionType, savedPostId: editData?.savedPostId, reqBody: reqPayload }));
    } else if (datePickerEnabled.dataObj && id) {
      reqPayload = getCalendarSchReqPayload(datePickerEnabled.dataObj, id);
    }
    if (userOwnership === USER_OWNERSHIP.ACCOUNT) {
      const actualData = {
        ...reqPayload,
        account_id: id,
        id: eventId || editData?.id,
        scheduled_for: savedPostDetails?.schedule_date_time.utcFormat
      };
      dispatch(
        updateScheduledPostRequest({
          activePlatforms,
          payload: actualData,
          isCalendarView: true,
          dateRange:
            currentPageView === CALENDAR_PAGE_VIEW_TYPES.CALENDAR && calendarDateRange?.startDate && calendarDateRange?.endDate
              ? [utcTimeStampConverter(calendarDateRange?.startDate), utcTimeStampConverter(calendarDateRange?.endDate)]
              : [],
          refetch: Boolean(isEditEnabled && datePickerEnabled?.isOpen) || refetch ? true : false
        })
      );
    }

    dispatch(calendarEditView({ isEditEnabled: false, editId: 0, editData: null }));
    editModalCloseHandler();
    resetDispatchHandler();
  };

  const canEditAccountDate =
    !isHubUser && editData?.id ? canEditScheduledPostDateAndTime(editData?.isCorporatePost, isFranchisee, overriddenCorporatePostsLocked, editData?.abilities?.can_edit_schedule) : false;

  const disableSaveAction = () => {
    if (userOwnership === USER_OWNERSHIP.FRANCHISOR && !getPreviewBrandHubCreatorActions(postStatus).length) return true;
    else if (userOwnership === USER_OWNERSHIP.ACCOUNT && savedPostDetails && !getPreviewSocialMediaList(savedPostDetails).length) return true;
    else return false;
  };

  const handleSchedule = (isScheduleEnabled: boolean) => {
    dispatch(calendarEnableSchedule(isScheduleEnabled));
  };

  const handleEnableDatePicker = (isEnable: boolean) => {
    dispatch(calendarEnableDatePicker({ isOpen: isEnable ? true : false, dataObj: isEnable ? selectedObj : null }));
    if (!isEnable) {
      if (editData) {
        dispatch(
          addPostUpdateSavedPostDetails(
            savedPostDetails
              ? {
                  ...savedPostDetails,
                  schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || '', editData?.scheduledTime),
                  account_schedule_date_time: dateObjBasedOnTimeZone(accountDetails?.account?.tzinfo_name || '', editData?.start_date)
                }
              : null
          )
        );
      }
    }
  };

  return (
    <>
      {savedPostDetails || contentData?.id ? (
        <CreateOrEditPostModal
          type={CommonAction.EDIT}
          isShowModal={isEditEnabled}
          onModalClose={() => {
            dispatch(calendarEditView({ editData: null, editId: 0, isEditEnabled: false }));
            resetDispatchHandler();
          }}
          rightSideActionSection={
            <div className="right-button-section">
              <CustomRippleButton rippleClass={`ac-primary-box`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-secondary ac-block filter-sc-btn${disableSaveAction() || (!isHubUser && !canEditAccountDate) ? ` events-none pointer-events-none` : ''}`}
                  onClick={() => (isHubUser ? handleSchedule(true) : handleEnableDatePicker(true))}
                >
                  Edit Schedule
                </button>
              </CustomRippleButton>
              <CustomRippleButton rippleClass={`ac-primary-box r-mt2`} custColor={RIPPLE_COLOR.primary}>
                <button
                  className={`ac-btn ac-primary filter-sc-btn ac-block${disableSaveAction() ? ` events-none pointer-events-none` : ''}`}
                  onClick={() => {
                    if (isHubUser) {
                      handleUpdateContentData(AP_CONTENT_ACTION_TYPE.SCHEDULE);
                      handleContentMassUpdate();
                    } else {
                      handleUpdateSavedPostData(AP_CONTENT_ACTION_TYPE.SCHEDULE);
                    }
                  }}
                >
                  Save
                </button>
              </CustomRippleButton>
              <span className="current-time-stamp">(Saved {humanReadableDate} ago)</span>
            </div>
          }
        />
      ) : null}

      {/* SCHEDULE MODAL FOR HUB USER */}
      {isScheduleEnabled ? (
        <BrandSchedulePostModal
          isModalShow
          handleModalClose={() => handleSchedule(false)}
          scheduleType={getInitialLocationScheduleType({
            totalLocationCount: locationsCount,
            corporateAccountId,
            includedAccounts: editData?.id ? editData?.includedAccounts || [] : selectedObj?.includedAccounts || [],
            franOrAccountListIds: editData?.id ? editData?.franchisorIds || editData?.accountListIds || [] : selectedObj?.franchisorIds || selectedObj?.accountListIds || []
          })}
          selectedObj={editData?.id ? editData : selectedObj}
          isEditEnabled
          handleUpdateContentData={handleUpdateContentData}
        />
      ) : null}

      {/* SCHEDULE MODAL FOR LOCATION USER */}
      {datePickerEnabled?.isOpen ? (
        <CalendarDatePicker
          dispatchFunction={(action, id) => handleUpdateSavedPostData(action, id, true)}
          modalOpenFunction={() => handleEnableDatePicker(false)}
          states={datePickerEnabled.dataObj || editData}
          isEditable={isEditEnabled}
          editData={editData}
          endDate={datePickerEnabled.dataObj?.end_date}
        />
      ) : null}

      {/* DELETE MODAL */}
      {deleteModal.isOpenDeleteModal ? (
        <CalendarDeleteAlertModal isLocationUser={!isHubUser} states={locationUpdateStates} dispatchFunction={isHubUser ? hubPostDeleteHandler : handleOnChangePostContent} />
      ) : null}
    </>
  );
};
