import { lazy } from 'react';

import { commonRouterTypes } from 'types';
import { ROUTE_TABS, ROUTES, CHILD_ROUTES } from 'utils/constants';
import { lazyRetry } from 'utils/helpers';

export const appRoutes: commonRouterTypes.IAppRoutes = {
  publicRoutes: [
    {
      name: ROUTE_TABS.LOGIN,
      route: ROUTES.LOGIN,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/Login'));
        return { default: component.LoginContainer };
      })
    },
    {
      name: ROUTE_TABS.FORGOT_PASSWORD,
      route: ROUTES.FORGOT_PASSWORD,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/ForgotPassword'));
        return { default: component.ForgotPasswordContainer };
      })
    },
    {
      name: ROUTE_TABS.RESET_PASSWORD,
      route: ROUTES.RESET_PASSWORD,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/ResetPassword'));
        return { default: component.ResetPasswordContainer };
      })
    },
    {
      name: ROUTE_TABS.SINGLE_SIGN_ON,
      route: ROUTES.SINGLE_SIGN_ON,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/SingleSignOn'));
        return { default: component.SingleSignOn };
      })
    },
    {
      name: ROUTE_TABS.SINGLE_SIGN_ON,
      route: ROUTES.SINGLE_SIGN_ON_ERROR,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/SingleSignOn'));
        return { default: component.SingleSignOn };
      })
    },
    {
      name: ROUTE_TABS.USER_CONFIRMATION,
      route: ROUTES.USER_CONFIRMATION,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/UserConfirmation/UserConfirmation'));
        return { default: component.UserConfirmation };
      })
    },
    {
      name: ROUTE_TABS.DOWNLOAD_RALLIO_APP,
      route: ROUTES.DOWNLOAD_RALLIO_APP,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Auth/ConfirmMyAccount/DownloadRallioApp'));
        return { default: component.DownloadRallioApp };
      })
    }
  ],

  privateRoutes: [
    {
      name: ROUTE_TABS.ANALYTICS_SOCIAL,
      route: ROUTES.ANALYTICS_SOCIAL,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('analytics/containers/OverviewAnalytics'));
        return { default: component.OverviewAnalytics };
      }),
      icon: '',
      menuAccessibleName: 'analytics_social',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ANALYTICS_REVIEW,
      route: ROUTES.ANALYTICS_REVIEW,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('analytics/containers/ReviewAnalytics'));
        return { default: component.AnalyticsReviewAnalytics };
      }),
      icon: 'side-nav-post',
      menuAccessibleName: 'analytics_reviews',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ANALYTICS_CONTENT,
      route: ROUTES.ANALYTICS_CONTENT,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('analytics/containers/ContentAnalytics'));
        return { default: component.AnalyticsContentAnalytics };
      }),
      icon: '',
      menuAccessibleName: 'analytics_content',
      // children: [
      //   {
      //     name: '',
      //     route: CHILD_ROUTES.ANALYTICS_CONTENT.CONTENT_ID,
      //     component: lazy(async () => {
      //       const component = await lazyRetry(() => import('analytics/containers/ContentAnalytics/SubPages/ContentModal'));
      //       return { default: component.ContentModal };
      //     })
      //   }
      // {
      //   name: '',
      //   route: CHILD_ROUTES.ANALYTICS_CONTENT.MULTIPLE_LOCATION_FACEBOOK,
      //   component: lazy(async () => {
      //     const component = await lazyRetry(() => import('analytics/containers/ContentAnalytics'));
      //     return { default: component.AnalyticsContentAnalytics };
      //   })
      // },
      // {
      //   name: '',
      //   route: CHILD_ROUTES.ANALYTICS_CONTENT.MULTIPLE_LOCATION_LINKEDIN,
      //   component: lazy(async () => {
      //     const component = await lazyRetry(() => import('analytics/containers/ContentAnalytics'));
      //     return { default: component.AnalyticsContentAnalytics };
      //   })
      // }
      // ],
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ANALYTICS_PAGE,
      route: ROUTES.ANALYTICS_PAGE,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('analytics/containers/ProfileAnalytics'));
        return { default: component.AnalyticsProfileAnalytics };
      }),
      icon: '',
      menuAccessibleName: 'analytics_page',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ANALYTICS_LEADERBOARD,
      route: ROUTES.ANALYTICS_LEADERBOARD,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('analytics/containers/LeaderBoardAnalytics'));
        return { default: component.LeaderboardComponent };
      }),
      icon: '',
      menuAccessibleName: 'analytics_leaderboard',
      isMenuAccessible: true,
      children: [
        {
          name: '',
          route: '',
          component: lazy(async () => {
            const component = await lazyRetry(() => import('analytics/containers/LeaderBoardAnalytics/SubPages'));
            return { default: component.LeaderBoardAnalytics };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ANALYTICS_LEADERBOARD.OPERATIONS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('analytics/containers/LeaderBoardAnalytics/SubPages/Operation/LeaderBoardAnalyticsOperation'));
            return { default: component.LeaderBoardAnalyticsOperation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ANALYTICS_LEADERBOARD.LOCATIONS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/leaderboard/locations'));
            return { default: component.LeaderboardLocations };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ANALYTICS_LEADERBOARD.TEAMS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/leaderboard/teams'));
            return { default: component.LeaderBoardTeams };
          })
        }
      ]
    },
    {
      name: ROUTE_TABS.CONTENT_CREATOR,
      route: ROUTES.CONTENT_CREATOR,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Creator'));
        return { default: component.ContentCreator };
      }),
      icon: '',
      menuAccessibleName: 'creator',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_POSTS,
      route: ROUTES.CONTENT_POSTS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Posts'));
        return { default: component.ContentPosts };
      }),
      icon: '',
      menuAccessibleName: 'posts',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.CONTENT_POSTS.FAVORITE_TAGS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/Modal/MgFavTagModalPopup'));
            return { default: component.MgFavTagModalPopup };
          })
        }
      ],
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_MEDIA,
      route: ROUTES.CONTENT_MEDIA,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/MediaGallery'));
        return { default: component.ContentMediaGallery };
      }),
      icon: '',
      // children: [
      //   {
      //     name: '',
      //     route: CHILD_ROUTES.CONTENT_POSTS.FAVORITE_TAGS,
      //     component: lazy(async () => {
      //       const component = await lazyRetry(() => import('components/Common/Modal/MgFavTagModalPopup'));
      //       return { default: component.MgFavTagModalPopup };
      //     })
      //   }
      // ],
      menuAccessibleName: 'media',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_FEED,
      route: ROUTES.CONTENT_FEED,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/TeamManagement/Lists'));
        return { default: component.Lists };
      }),
      icon: '',
      menuAccessibleName: 'feed',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_SCHEDULER,
      route: ROUTES.CONTENT_SCHEDULER,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Scheduler'));
        return { default: component.Scheduler };
      }),
      icon: '',
      menuAccessibleName: 'scheduler',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_CAMPAIGNS,
      route: ROUTES.CONTENT_CAMPAIGNS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Campaigns'));
        return { default: component.ContentCampaigns };
      }),
      icon: '',
      menuAccessibleName: 'campaigns',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_COUPONS,
      route: ROUTES.CONTENT_COUPONS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Coupons'));
        return { default: component.ContentCoupons };
      }),
      icon: '',
      menuAccessibleName: 'coupons',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_CALENDAR,
      route: ROUTES.CONTENT_CALENDAR,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/Calendar'));
        return { default: component.ContentCalendar };
      }),
      icon: '',
      menuAccessibleName: 'calendar',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_RSS_FEED,
      route: ROUTES.CONTENT_RSS_FEED,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/RSSFeed'));
        return { default: component.ContentRSSFeed };
      }),
      icon: '',
      menuAccessibleName: 'rss_feeds',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.CONTENT_PROFILE_IMAGERY,
      route: ROUTES.CONTENT_PROFILE_IMAGERY,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Content/ProfileImagery'));
        return { default: component.ProfileImagery };
      }),
      icon: '',
      menuAccessibleName: 'profile_imagery',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.COMMUNITY_INBOX,
      route: ROUTES.COMMUNITY_INBOX,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Community/InBox'));
        return { default: component.CommunityInbox };
      }),
      icon: '',
      menuAccessibleName: 'inbox',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.COMMUNITY_SANDBOX,
      route: ROUTES.COMMUNITY_SANDBOX,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Community/SandBox'));
        return { default: component.CommunitySandbox };
      }),
      icon: '',
      menuAccessibleName: 'sandbox',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.MARKETING_PROSPECTS_CLIENTS,
      route: ROUTES.MARKETING_PROSPECTS_CLIENTS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Marketing/ProspectsClient'));
        return { default: component.ProspectsClient };
      }),
      icon: '',
      menuAccessibleName: 'prospects_clients',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.MARKETING_FB_ADS,
      route: ROUTES.MARKETING_FB_ADS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Marketing/FBAds'));
        return { default: component.MarketingFBAds };
      }),
      icon: '',
      menuAccessibleName: 'fb_ads_marketing',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REPUTATION_OUTBOX,
      route: ROUTES.REPUTATION_OUTBOX,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Reputation/OutBox'));
        return { default: component.ReputationOutbox };
      }),
      icon: '',
      menuAccessibleName: 'outbox',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REPUTATION_REVIEWS,
      route: ROUTES.REPUTATION_REVIEWS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Reputation/Reviews'));
        return { default: component.ReputationReviews };
      }),
      icon: '',
      menuAccessibleName: 'reviews',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REPUTATION_AI_REVIEW_RESPONDER,
      route: ROUTES.REPUTATION_AI_REVIEW_RESPONDER,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Reputation/AIReviewResponder'));
        return { default: component.AIReviewResponder };
      }),
      icon: '',
      menuAccessibleName: 'ai_review_responder',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV,
      route: ROUTES.REVV,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Sales'));
        return { default: component.Sales };
      }),
      icon: '',
      menuAccessibleName: 'login',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_DASHBOARD,
      route: ROUTES.REVV_DASHBOARD,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/dashboard'));
        return { default: component.RevvDashboard };
      }),
      menuAccessibleName: 'dashboard',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_SURVEYS,
      route: ROUTES.REVV_SURVEYS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/surveys'));
        return { default: component.RevvSurveys };
      }),
      menuAccessibleName: 'surveys',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_REPORTS,
      route: ROUTES.REVV_REPORTS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/reports'));
        return { default: component.RevvReports };
      }),
      menuAccessibleName: 'reports',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_ANALYTICS,
      route: ROUTES.REVV_ANALYTICS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/analytics'));
        return { default: component.RevvAnalytics };
      }),
      menuAccessibleName: 'analytics_revv',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_ANALYTICS_SURVEY_STATUS,
      route: ROUTES.REVV_ANALYTICS_SURVEY_STATUS,
      menuAccessibleName: 'survey-status',
      isMenuAccessible: true,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/analytics/survey-status'));
        return { default: component.SurveyStatus };
      })
    },
    {
      name: ROUTE_TABS.REVV_ANALYTICS_SENTIMENT,
      route: ROUTES.REVV_ANALYTICS_SENTIMENT,
      menuAccessibleName: 'sentiment',
      isMenuAccessible: true,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/analytics/sentiment-over-time'));
        return { default: component.SentimentOverTime };
      })
    },
    {
      name: ROUTE_TABS.REVV_ANALYTICS_REVIEW,
      route: ROUTES.REVV_ANALYTICS_REVIEW,
      menuAccessibleName: 'review_change',
      isMenuAccessible: true,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/analytics/review-calculator'));
        return { default: component.ReviewCalculator };
      })
    },
    {
      name: ROUTE_TABS.REVV_ANALYTICS_AVERAGE_RATING,
      route: ROUTES.REVV_ANALYTICS_AVERAGE_RATING,
      menuAccessibleName: 'average_rating',
      isMenuAccessible: true,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/analytics/average-rating'));
        return { default: component.AverageRating };
      })
    },
    {
      name: ROUTE_TABS.REVV_TEAMS,
      route: ROUTES.REVV_TEAMS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/teams'));
        return { default: component.RevvTeams };
      }),
      menuAccessibleName: 'revv_teams',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_TEAMS_DETAIL,
      route: ROUTES.REVV_TEAMS_DETAIL,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/teams/team-detail-view'));
        return { default: component.TeamDetailView };
      }),
      menuAccessibleName: 'revv_teams',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_CUSTOMERS,
      route: ROUTES.REVV_CUSTOMERS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/customer'));
        return { default: component.RevvCustomers };
      }),
      menuAccessibleName: 'customers',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_LOCATION,
      route: ROUTES.REVV_LOCATION,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/leaderboard/locations'));
        return { default: component.LeaderboardLocations };
      }),
      menuAccessibleName: 'locations',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_EMPLOYEES,
      route: ROUTES.REVV_EMPLOYEES,
      component: lazy(async () => {
        // const component = await lazyRetry(() => import('revv/containers/teams'));
        // return { default: component.RevvTeams };
        const component = await lazyRetry(() => import('revv/containers/leaderboard/teams'));
        return { default: component.LeaderBoardTeams };
      }),
      menuAccessibleName: 'employees',
      isMenuAccessible: true
    },
    // {
    //   name: ROUTE_TABS.REVV_CUSTOMERS_DETAIL,
    //   route: ROUTES.REVV_CUSTOMERS_DETAIL,
    //   component: lazy(async () => {
    //     const component = await lazyRetry(() => import('revv/containers/customer/customer-detail-view'));
    //     return { default: component.CustomerDetailView };
    //   }),
    //   menuAccessibleName: 'customers',
    //   isMenuAccessible: true
    // },

    // {
    //   name: ROUTE_TABS.REVV_COMMUNITY,
    //   route: ROUTES.REVV_COMMUNITY,
    //   component: lazy(async () => {
    //     const component = await lazyRetry(() => import('revv/containers/location'));
    //     return { default: component.RevvLocations };
    //   }),
    //   menuAccessibleName: 'revv',
    //   isMenuAccessible: true,
    //   children: [
    //     {
    //       name: '',
    //       route: CHILD_ROUTES.REVV_COMMUNITY.REVV_LOCATIONS,
    //       component: lazy(async () => {
    //         const component = await lazyRetry(() => import('revv/containers/location/container-root'));
    //         return { default: component.LocationsComponent };
    //       })
    //     },
    //     {
    //       name: '',
    //       route: CHILD_ROUTES.REVV_COMMUNITY.REVV_CUSTOMERS,
    //       component: lazy(async () => {
    //         const component = await lazyRetry(() => import('revv/containers/customer'));
    //         return { default: component.RevvCustomers };
    //       })
    //     },
    //     {
    //       name: '',
    //       route: CHILD_ROUTES.REVV_COMMUNITY.CUSTOMERS_DETAILS,
    //       component: lazy(async () => {
    //         const component = await lazyRetry(() => import('revv/containers/customer/customer-detail-view'));
    //         return { default: component.CustomerDetailView };
    //       })
    //     }
    //   ]
    // },
    {
      name: ROUTE_TABS.ADVOCACY_OVERVIEW,
      route: ROUTES.ADVOCACY_OVERVIEW,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Advocacy/Overview'));
        return { default: component.AdvocacyOverview };
      }),
      icon: '',
      menuAccessibleName: 'overview',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ADVOCACY_LEADER_BOARD,
      route: ROUTES.ADVOCACY_LEADER_BOARD,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Advocacy/AdvocacyLeaderBoard'));
        return { default: component.AdvocacyLeaderBoard };
      }),
      icon: '',
      menuAccessibleName: 'advocacy_leaderboard',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ADVOCACY_REWARD_PROGRAM,
      route: ROUTES.ADVOCACY_REWARD_PROGRAM,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Advocacy/RewardPrograms'));
        return { default: component.AdvocacyRewardPrograms };
      }),
      icon: '',
      menuAccessibleName: 'reward_programs',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.ADVOCACY_REWARD_PROGRAM.DETAIL_VIEW,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/CreateProgram/CreateProgramModal'));
            return { default: component.CreateProgramModal };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADVOCACY_REWARD_PROGRAM.ACHEIVERS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/CreateProgram/CreateProgramModal'));
            return { default: component.CreateProgramModal };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADVOCACY_REWARD_PROGRAM.NEW,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/CreateProgram/CreateProgramModal'));
            return { default: component.CreateProgramModal };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADVOCACY_REWARD_PROGRAM.EDIT,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/CreateProgram/CreateProgramModal'));
            return { default: component.CreateProgramModal };
          })
        }
      ],
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.DIRECTORY_LISTINGS_PROFILE,
      route: ROUTES.DIRECTORY_LISTINGS_PROFILE,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/DirectoryListings/Profile'));
        return { default: component.Profile };
      }),
      children: [
        {
          name: '',
          route: CHILD_ROUTES.DIRECTORY_LISTINGS_PROFILE.BUSINESS_DETAILS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/DirectoryListings/Profile/SubPages/StoreDetails'));
            return { default: component.StoreDetails };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.DIRECTORY_LISTINGS_PROFILE.BUSINESS_HOURS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/DirectoryListings/Profile/SubPages/BusinessHours'));
            return { default: component.BusinessHours };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.DIRECTORY_LISTINGS_PROFILE.ADD_PHOTOS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/DirectoryListings/Profile/SubPages/AddPhoto'));
            return { default: component.AddPhoto };
          })
        }
      ],
      icon: '',
      menuAccessibleName: 'profile',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.DIRECTORY_LISTINGS_LISTINGS,
      route: ROUTES.DIRECTORY_LISTINGS_LISTINGS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/DirectoryListings/Listings'));
        return { default: component.Listings };
      }),
      icon: '',
      menuAccessibleName: 'listings',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.DIRECTORY_LISTINGS_RANKINGS,
      route: ROUTES.DIRECTORY_LISTINGS_RANKINGS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/DirectoryListings/Rankings'));
        return { default: component.Rankings };
      }),
      icon: '',
      menuAccessibleName: 'rankings',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.TEAM_MANAGEMENT_CONNECTIONS,
      route: ROUTES.TEAM_MANAGEMENT_CONNECTIONS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/TeamManagement/Connections'));
        return { default: component.OperationsConnections };
      }),
      icon: '',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.USERS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/TeamManagement/Connections/SubPages/ManageAdminLogins'));
            return { default: component.ManageAdminLogins };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.EMPLOYEES,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/TeamManagement/Connections/SubPages/ManageAdminLogins'));
            return { default: component.ManageAdminLogins };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.NEW_RALLIO_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.RALLIO_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.UPGRADE_PACKAGE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.SOCIAL_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.FEED,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.TEAM_MANAGEMENT_CONNECTIONS.SCHEDULER,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        }
      ],
      menuAccessibleName: 'connections',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.TEAM_MANAGEMENT_LISTS,
      route: ROUTES.TEAM_MANAGEMENT_LISTS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/TeamManagement/Lists'));
        return { default: component.Lists };
      }),
      icon: '',
      menuAccessibleName: 'lists',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.TEAM_MANAGEMENT_LOGINS,
      route: ROUTES.TEAM_MANAGEMENT_LOGINS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/TeamManagement/Logins'));
        return { default: component.Logins };
      }),
      icon: '',
      menuAccessibleName: 'logins',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.TEAM_MANAGEMENT_PERMISSIONS,
      route: ROUTES.TEAM_MANAGEMENT_PERMISSIONS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/TeamManagement/Permissions'));
        return { default: component.Permissions };
      }),
      icon: '',
      menuAccessibleName: 'permissions',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.SETTINGS_BRAND_PROFILE,
      route: ROUTES.SETTINGS_BRAND_PROFILE,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/SettingsBrandProfile'));
        return { default: component.SettingsBrandProfile };
      }),
      icon: '',
      menuAccessibleName: 'brand_profile',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.SETTINGS_PARTNER_BANNERS,
      route: ROUTES.SETTINGS_PARTNER_BANNERS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/SettingsPartnerBanners'));
        return { default: component.SettingsPartnerBanners };
      }),
      icon: '',
      menuAccessibleName: 'partner_banners',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.SETTINGS_REVIEW_TEMPLATE,
      route: ROUTES.SETTINGS_REVIEW_TEMPLATE,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/ReviewTemplate'));
        return { default: component.ReviewTemplate };
      }),
      icon: '',
      menuAccessibleName: 'reviews_settings',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.SETTINGS_RELEASE_FORM,
      route: ROUTES.SETTINGS_RELEASE_FORM,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/ModalRelease'));
        return { default: component.ModalRelease };
      }),
      icon: '',
      menuAccessibleName: 'release_form',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.SETTINGS_RALLIO,
      route: ROUTES.SETTINGS_RALLIO,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/SettingsRallioProfile'));
        return { default: component.SettingsRallioProfile };
      }),
      icon: '',
      menuAccessibleName: 'rallio_profile',
      isMenuAccessible: true
    },

    {
      name: ROUTE_TABS.SETTINGS_SOCIAL,
      route: ROUTES.SETTINGS_SOCIAL,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/SettingsSocialProfile'));
        return { default: component.SettingsSocialProfile };
      }),
      children: [
        {
          name: '',
          route: CHILD_ROUTES.SETTINGS_SOCIAL.CONNECT_INSTAGRAM,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/Settings/SettingsSocialProfile/SubPages/InstagramConnectModal'));
            return { default: component.InstagramConnectModal };
          })
        }
      ],
      icon: '',
      menuAccessibleName: 'social_profile',
      isMenuAccessible: true
    },

    {
      name: ROUTE_TABS.SETTINGS_AI_PLAYBOOK,
      route: ROUTES.SETTINGS_AI_PLAYBOOK,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/SettingsAIPlaybook'));
        return { default: component.AIPlaybook };
      }),

      icon: '',
      menuAccessibleName: 'ai_playbook',
      isMenuAccessible: true
    },
    // {
    //   name: ROUTE_TABS.SETTINGS_HASHTAGS,
    //   route: ROUTES.SETTINGS_HASHTAGS,
    //   component: lazy(async () => {
    //     const component = await lazyRetry(() => import('containers/Settings/SettingsHashtags'));
    //     return { default: component.SettingsHashtags };
    //   }),

    //   icon: '',
    //   menuAccessibleName: 'hashtags',
    //   isMenuAccessible: true
    // },
    {
      name: ROUTE_TABS.SETTINGS_FB_ADS,
      route: ROUTES.SETTINGS_FB_ADS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Settings/FBAds'));
        return { default: component.SettingsFBAds };
      }),
      icon: '',
      menuAccessibleName: 'fb_ads_settings',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.REVV_SETTINGS,
      route: ROUTES.REVV_SETTINGS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('revv/containers/settings'));
        return { default: component.RevvSettings };
      }),
      icon: '',
      menuAccessibleName: 'revv_settings',
      isMenuAccessible: true,
      children: [
        {
          name: '',
          route: CHILD_ROUTES.REVV_SETTINGS.REVV_GLOBAL,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/settings/subPage/global'));
            return { default: component.GlobalContainer };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.REVV_SETTINGS.REVV_OPTIN,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/settings/subPage/optIn'));
            return { default: component.OptInContainer };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.REVV_SETTINGS.REVV_SURVEY,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/settings/subPage/survey'));
            return { default: component.SurveyContainer };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.REVV_SETTINGS.REVV_COUPON,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/settings/subPage/coupons'));
            return { default: component.CouponsContainer };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.REVV_SETTINGS.REVV_CONNECTION,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('revv/containers/settings/subPage/connection'));
            return { default: component.ConnectionsContainer };
          })
        }
      ]
    },
    {
      name: ROUTE_TABS.MARKETING_TARGETING,
      route: ROUTES.MARKETING_TARGETING,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Marketing/Targeting'));
        return { default: component.Targeting };
      }),
      icon: '',
      menuAccessibleName: 'targeting',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.VIDEO_TUTORIALS,
      route: ROUTES.VIDEO_TUTORIALS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/VideoTutorials'));
        return { default: component.VideoTutorials };
      }),
      icon: '',
      menuAccessibleName: 'tutorials',
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.AI_ASSISTANT,
      route: ROUTES.AI_ASSISTANT,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/AIAssistant'));
        return { default: component.AIAssistant };
      }),
      icon: '',
      menuAccessibleName: 'ai_assistant',
      isMenuAccessible: true
    }
  ],

  superAdminRoutes: [
    {
      name: ROUTE_TABS.ADMIN_ACCOUNTS,
      route: ROUTES.ADMIN_ACCOUNTS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Admin/AdminArea/Accounts'));
        return { default: component.Accounts };
      }),
      icon: '',
      menuAccessibleName: 'accounts',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_ACCOUNTS.RALLIO_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_ACCOUNTS.UPGRADE_PACKAGE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_ACCOUNTS.SOCIAL_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_ACCOUNTS.FEED,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_ACCOUNTS.SCHEDULER,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        }
      ],
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ADMIN_FRANCHISORS,
      route: ROUTES.ADMIN_FRANCHISORS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Admin/AdminArea/Franchisors'));
        return { default: component.Franchisors };
      }),
      icon: '',
      menuAccessibleName: 'franchisors',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_FRANCHISORS.NEW_RALLIO_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_FRANCHISORS.RALLIO_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_FRANCHISORS.UPGRADE_PACKAGE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_FRANCHISORS.SOCIAL_PROFILE,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/AddOrEditLocation/AddOrEditLocation'));
            return { default: component.AddOrEditLocation };
          })
        },
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_FRANCHISORS.FRANCHISEE_STATS,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('containers/Admin/AdminArea/Franchisors/Subpages/FranchisorStatsView'));
            return { default: component.FranchisorStatsView };
          })
        }
      ],
      isMenuAccessible: true
    },
    {
      name: ROUTE_TABS.ADMIN_USERS,
      route: ROUTES.ADMIN_USERS,
      component: lazy(async () => {
        const component = await lazyRetry(() => import('containers/Admin/AdminArea/Users'));
        return { default: component.Users };
      }),
      icon: '',
      menuAccessibleName: 'users',
      children: [
        {
          name: '',
          route: CHILD_ROUTES.ADMIN_USERS.ADMIN_USERS_ID,
          component: lazy(async () => {
            const component = await lazyRetry(() => import('components/Common/Modal/AdminUserDetailModal'));
            return { default: component.AdminUserDetailModal };
          })
        }
      ],
      isMenuAccessible: true
    }
  ]
};
